import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import { Company } from '@models/company'
import InfoField from '@organisms/InfoField'
import { Routes } from '@enums/Routes'

type Props = {
  company: Pick<Company, 'id' | 'title'>
}

const CompanyInfoField: FC<Props> = ({ company }) => {
  const { isAdmin } = useMe()
  const navigate = useNavigate()

  const handleOpen = async () => {
    navigate(Routes.COMPANIES + `?id=${company.id}`)
  }

  if (isAdmin) {
    return (
      <InfoField
        onTabClick={handleOpen}
        label="Компания"
        value={company.title}
        withStopPropagation={false}
      />
    )
  }

  return null
}

export default CompanyInfoField
