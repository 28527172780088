import { useLazyDownloadFromApiFilesQuery } from '@services/files'

const useDownloadFile = (fileIri: string | null | undefined) => {
  const [downloadFileFromApi, { isLoading }] =
    useLazyDownloadFromApiFilesQuery()

  const download = () => {
    if (!fileIri) {
      console.warn('useDownloadFile fileIri not found', { fileIri })
      return
    }

    void downloadFileFromApi(fileIri.split('/api/files/')[1]!)
  }

  return {
    download,
    isLoading,
  }
}

export default useDownloadFile
