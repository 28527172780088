import { Dispatch, FC, SetStateAction } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { isBoolean, isEmpty } from 'lodash'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import { FromButtons } from '@templates/AddNewItemModal'
import { log } from '@utils/console'
import { Footer } from './styled'

export const prepareValues = (values: FieldValues) => {
  const resultValues: FieldValues = {}
  for (const key in values) {
    const value = values[key]
    if (!isEmpty(value) || isBoolean(value)) {
      resultValues[key] = value
    }
  }

  log('form values:', resultValues)
  return resultValues
}

type Props = {
  isEdit?: boolean
  onSubmitForm: (form: any) => void // correct
  onSaveAndAddMoreText?: string
  isLoading?: boolean
  withSaveAndAddMore?: boolean
  onSaveAndAddMore?: () => void
  saveText?: string
  fromButton: FromButtons
  setFromButton: Dispatch<SetStateAction<FromButtons>>
  handleCloseModal?: () => void
  data?: any
}

const FooterComponent: FC<Props> = ({
  isEdit = false,
  onSubmitForm,
  isLoading,
  onSaveAndAddMoreText,
  withSaveAndAddMore = false,
  onSaveAndAddMore,
  saveText,
  fromButton,
  setFromButton,
}) => {
  const { handleSubmit } = useFormContext()

  const handleSaveAndAddMore = (values: FieldValues) => {
    setFromButton(FromButtons.ADD_MORE)
    onSubmitForm(prepareValues(values))
    onSaveAndAddMore?.()
  }

  const handleSubmitForm = (values: FieldValues) => {
    setFromButton(FromButtons.MAIN)
    onSubmitForm(prepareValues(values))
  }

  return (
    <Footer>
      {!isEdit && withSaveAndAddMore && (
        <SecondaryButton
          onClick={handleSubmit(handleSaveAndAddMore)}
          isLoading={fromButton === FromButtons.ADD_MORE && isLoading}
          id="modal-save-and-add-more"
        >
          {onSaveAndAddMoreText || 'Сохранить и добавить еще'}
        </SecondaryButton>
      )}
      <PrimaryButton
        onClick={handleSubmit(handleSubmitForm)}
        type="submit"
        isLoading={fromButton === FromButtons.MAIN && isLoading}
        id="modal-save"
      >
        {saveText || 'Сохранить'}
      </PrimaryButton>
    </Footer>
  )
}

export type FooterProps = Props
export default FooterComponent
