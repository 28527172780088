import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopBottomWrapper = styled.div`
  margin-bottom: -10px;
  display: flex;
  flex-direction: row;
  margin-top: 0;
  flex-wrap: wrap;
`
const MobileBottomWrapper = styled(DesktopBottomWrapper)`
  flex-direction: column;
  margin-top: 10px;
`
export const BottomWrapper = isMobile
  ? MobileBottomWrapper
  : DesktopBottomWrapper

export const Wrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const StyledTitle = styled(Title)`
  white-space: pre-line;
`
