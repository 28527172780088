/* eslint-disable sonarjs/no-duplicate-string */
import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import Table, { Column } from '@organisms/Table'
import { RiskMapDetailed } from '@models/riskMap'
import EditRiskMapButton from '@pages/RiskMaps/RiskMap/Body/EditRiskMapButton'
import { useGetProtectionMeansByRiskMapGroupedQuery } from '@services/protectionMeans'
import {
  DangerEvent,
  DangerEventsWrapper,
  UpdateAllWrapper,
  Wrapper,
} from './styled'
import UpdateBefore from './UpdateBefore'
import UpdateAll from './UpdateAll'

const COLUMNS: Column[] = [
  {
    title: 'Конструкция',
    dataIndex: 'construction',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
  },
  {
    title: 'Опасное событие',
    dataIndex: 'dangerous',
  },
  {
    title: '№ п/п ЕТН',
    dataIndex: 'etnNumber',
    width: 60,
  },
  {
    title: 'Кол-во',
    dataIndex: 'count',
    width: 60,
  },
  {
    title: 'Период обновл.',
    dataIndex: 'period',
    width: 60,
  },
  {
    title: 'Доп СИЗ',
    dataIndex: 'additional',
  },
  {
    title: 'Обновить до',
    dataIndex: 'updateBefore',
    width: 60,
  },
]

type DataItem = {
  dangerous: ReactNode
  type: string
  construction: string
  etnNumber: string
  count: string
  period: string
  additional: ReactNode
  updateBefore: ReactNode
  key: string
  className: string | null
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
  noActions?: boolean
}

const ProtectionMeans: FC<Props> = ({
  riskMap,
  onChangeSize,
  index,
  noActions,
}) => {
  const { data } = useGetProtectionMeansByRiskMapGroupedQuery({
    riskMap: riskMap['@id'],
  })
  const items = data?.items || []

  useEffect(() => {
    if (data) {
      onChangeSize()
    }
  }, [data])

  const tabs = items.reduce(
    (
      acc,
      {
        '@id': idVal,
        construction,
        quantity,
        updateFrequency,
        updateAt,
        isAdditional,
        etnNumbers,
        means,
        type,
        childMeans,
      }
    ) => {
      acc.push({
        key: idVal,
        type,
        dangerous: (
          <DangerEventsWrapper>
            {means.map(({ event, dangerIri, iri }) => {
              const dangersIndex = riskMap.dangers.findIndex(
                (item) => item['@id'] === dangerIri
              )

              const protectionMeansIndex = riskMap.dangers[
                dangersIndex
              ]?.individualProtectionMeans.findIndex(
                (item) => item['@id'] === iri
              )

              return (
                <DangerEvent key={event}>
                  {noActions ? null : (
                    <EditRiskMapButton
                      riskMap={riskMap}
                      dangersIndex={dangersIndex}
                      protectionMeansIndex={protectionMeansIndex}
                    />
                  )}
                  <div>{event}</div>
                </DangerEvent>
              )
            })}
          </DangerEventsWrapper>
        ),
        construction: construction || '',
        etnNumber: etnNumbers || '',
        count: quantity,
        period: updateFrequency ? String(updateFrequency) : '',
        additional: isAdditional ? 'Дa' : null,
        updateBefore: updateAt ? <UpdateBefore date={updateAt} /> : '',
        className: isAdditional ? 'table-highlighted' : null,
      })

      if (childMeans) {
        childMeans.forEach((childMean) => {
          acc.push({
            key: childMean['@id'],
            type: childMean.type,
            dangerous: (
              <DangerEventsWrapper>
                {childMean.means.map(({ event, dangerIri, iri }) => {
                  const dangersIndex = riskMap.dangers.findIndex(
                    (item) => item['@id'] === dangerIri
                  )

                  const protectionMeansIndex = riskMap.dangers[
                    dangersIndex
                  ]?.individualProtectionMeans.findIndex(
                    (item) => item['@id'] === iri
                  )

                  return (
                    <DangerEvent key={event}>
                      {noActions ? null : (
                        <EditRiskMapButton
                          riskMap={riskMap}
                          dangersIndex={dangersIndex}
                          protectionMeansIndex={protectionMeansIndex}
                        />
                      )}
                      <div>{event}</div>
                    </DangerEvent>
                  )
                })}
              </DangerEventsWrapper>
            ),
            construction: childMean.construction || '',
            etnNumber: childMean.etnNumbers || '',
            count: childMean.quantity,
            period: childMean.updateFrequency
              ? String(childMean.updateFrequency)
              : '',
            additional: 'Дa, к предыдущему',
            updateBefore: childMean.updateAt ? (
              <UpdateBefore date={childMean.updateAt} />
            ) : (
              ''
            ),
            className: 'table-highlighted',
          })
        })
      }

      return acc
    },
    [] as DataItem[]
  )

  return (
    <>
      <Wrapper id={`protectionMeans-tab-content-${index}`}>
        <Table columns={COLUMNS} data={tabs} />
      </Wrapper>
      {!noActions && (
        <UpdateAllWrapper>
          <UpdateAll riskMap={riskMap} />
        </UpdateAllWrapper>
      )}
    </>
  )
}

export default ProtectionMeans
