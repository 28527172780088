import * as React from 'react'
import { FC } from 'react'
import {
  useArchiveWorkPlaceMutation,
  useUnarchiveWorkPlaceMutation,
} from '@services/workPlaces'
import GrayButton from '@organisms/GrayButton'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import ButtonWithConfirm from '@templates/ButtonWithConfirm'
import { DataItem } from '../index'

type Props = {
  record: DataItem
}

const ArchiveWorkPlace: FC<Props> = ({ record: { id, status } }) => {
  const [archive, { isLoading: archiveIsLoading }] =
    useArchiveWorkPlaceMutation()
  const [unarchive, { isLoading: unarchiveIsLoading }] =
    useUnarchiveWorkPlaceMutation()

  const handleArchive = () => {
    void archive(id)
  }

  const handleUnarchive = () => {
    void unarchive(id)
  }

  if (status === 'archived') {
    return (
      <GrayButton
        leftIcon={<UploadOutlined />}
        onClick={handleUnarchive}
        isLoading={unarchiveIsLoading}
      >
        Вернуть из архива
      </GrayButton>
    )
  }

  return (
    <ButtonWithConfirm
      ButtonComponent={(props) => (
        <GrayButton
          {...props}
          leftIcon={<DownloadOutlined />}
          isLoading={archiveIsLoading}
        >
          В архив
        </GrayButton>
      )}
      onConfirm={handleArchive}
      confirmText="После переноса рабочего места в архив, оно не будет показано сотрудникам. Перенести в архив?"
      confirmButtonText="Подтвердить"
      cancelText="Назад"
    />
  )
}

export default ArchiveWorkPlace
