import { useToggle } from 'react-use'
import { FC, MouseEventHandler } from 'react'
import SecondaryButton from '@organisms/SecondaryButton'
import { CopyOutlined } from '@ant-design/icons'
import Modal from '@organs/Modal'
import RiskMapModal from '@modals/RiskMapModal'
import { useLazyGetRiskMapQuery } from '@services/riskMaps'

type Props = {
  riskMapId: number
}

const CopyRiskMap: FC<Props> = ({ riskMapId }) => {
  const [modalIsVisible, toggleModalIsVisible] = useToggle(false)

  const [getRiskMap, { data, isLoading }] = useLazyGetRiskMapQuery()

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation()

    void getRiskMap(riskMapId)

    toggleModalIsVisible(true)
  }

  const handleClose = () => {
    toggleModalIsVisible(false)
  }

  return (
    <div>
      <SecondaryButton
        isLoading={isLoading}
        leftIcon={<CopyOutlined />}
        onClick={handleClick}
      />
      {data && (
        <Modal
          onModalClose={handleClose}
          visible={modalIsVisible}
          withoutCloseOnOutsideClick
        >
          <RiskMapModal
            riskMap={{
              typeOfName: data.typeOfName,
              typeOfAddingProtectionMeans: data.typeOfAddingProtectionMeans,
              autoAddProtectionMeans: data.autoAddProtectionMeans,
              dangers: data.dangers,
              dangersControlMeasureWorkplace:
                data.dangersControlMeasureWorkplace,
            }}
            handleCloseModal={handleClose}
          />
        </Modal>
      )}
    </div>
  )
}

export default CopyRiskMap
