import { UseFormReturn } from 'react-hook-form'
import { sub } from 'date-fns'
import useJobFilters from '@hooks/useJobFilters'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'
import { useGetJobConditionAssessmentsClassesQuery } from '@services/jobConditionAssesments'
import { Option } from '@organisms/Select'
import { toServerDate } from '@utils/date'

const useWorkPlacesFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division, position } = useJobFilters(methods)
  const { data } = useGetJobConditionAssessmentsClassesQuery()
  const classesOptions: Option[] = data?.items || []

  const filters: FiltersProps = []

  if (company) {
    filters.push(company)
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'companyBranch',
    })
  }

  if (division) {
    filters.push({
      ...division,
      fieldNameForSubmit: 'subdivision',
    })
  }

  if (position) {
    filters.push({
      ...position,
      fieldNameForSubmit: 'jobPosition',
    })
  }

  filters.push(
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'jobConditionAssessment.finalJobConditionClass',
      placeholder: 'Итоговый класс УТ',
      options: classesOptions.map((item) => ({
        ...item,
        label: (<span>&#8805; {item.label}</span>) as unknown as string,
      })),
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'status',
      placeholder: 'Статус',
      options: [
        {
          value: ['active', 'archived'],
          label: 'Все',
        },
        {
          value: 'active',
          label: 'Активна',
        },
        {
          value: 'archived',
          label: 'Архив',
        },
      ],
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'jobConditionAssessment.date[before]',
      placeholder: 'Срок действия',
      options: [
        {
          value: null,
          label: 'Все',
        },
        {
          value: toServerDate(sub(new Date(), { years: 4, months: 6 })),
          label: 'Меньше 6 месяцев',
        },
        {
          value: toServerDate(sub(new Date(), { years: 4, months: 3 })),
          label: 'Меньше 3 месяцев',
        },
        {
          value: toServerDate(sub(new Date(), { years: 5 })),
          label: 'Просрочено',
        },
      ],
      withoutFetchOptions: true,
      promptText: 'Срок действия карты 5 лет',
    },
    {
      filterType: FilterTypes.CHECKBOX,
      fieldName: 'check1',
      label: 'Не указана должность',
      valueOnChecked: 'false',
      promptText:
        'Для карт СОУТ нужно указывать должности из платформы, чтобы сотрудники могли увидеть эти карты',
    },
    {
      filterType: FilterTypes.CHECKBOX,
      fieldName: 'check2',
      label: 'Декларируемое рабочее место',
      valueOnChecked: 'false',
    }
  )

  return filters
}

export default useWorkPlacesFilters
