export const getBrowserName = () => {
  let name = 'Unknown'
  if (navigator.userAgent.includes('MSIE')) {
    name = 'MSIE'
  } else if (navigator.userAgent.includes('Firefox')) {
    name = 'Firefox'
  } else if (navigator.userAgent.includes('Opera')) {
    name = 'Opera'
  } else if (navigator.userAgent.includes('Chrome')) {
    name = 'Chrome'
  } else if (navigator.userAgent.includes('Safari')) {
    name = 'Safari'
  }
  return name
}
