import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import Table, { Column } from '@organisms/Table'
import { useGetRiskMapVersionsQuery } from '@services/riskMapVersions'
import { createInitFilter } from '@utils/filters'
import { RiskMapDetailed } from '@models/riskMap'
import RISK_MAP_STATUSES_CONFIG from '@templates/RiskMapStatus/config'
import { formatServerDate } from '@utils/date'
import { FileTwoTone } from '@ant-design/icons'
import RiskMapAuthor from '@templates/RiskMapAuthor'
import useDownloadFile from '@hooks/useDownloadFile'
import { Wrapper } from './styled'

const FileComponent: FC<{ children: string | undefined }> = (props) => {
  const { download } = useDownloadFile(props.children!)

  if (!props.children) return null

  return <FileTwoTone onClick={download} />
}

const COLUMNS: Column[] = [
  {
    title: 'Статус пересмотра',
    dataIndex: 'status',
  },
  {
    title: 'Дата изменения',
    dataIndex: 'date',
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
  },
  {
    title: 'Кто изменил',
    dataIndex: 'author',
  },
  {
    title: '',
    dataIndex: 'file',
  },
]

type DataItem = {
  status: string | number
  date: string
  comment: string
  author: ReactNode
  file: ReactNode
  key: string
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
  noAuthorLink?: boolean
}

const VersionsHistory: FC<Props> = ({
  riskMap,
  onChangeSize,
  index,
  noAuthorLink,
}) => {
  const { data, isLoading } = useGetRiskMapVersionsQuery(
    createInitFilter('riskMap', riskMap['@id'])
  )
  const items = data?.items || []
  // const length = data?.length || 0

  useEffect(() => {
    onChangeSize()
  }, [items])

  const tableData: DataItem[] = []
  for (const item of items) {
    tableData.push({
      key: item['@id'],
      status: RISK_MAP_STATUSES_CONFIG[item.status].title!,
      date: item.createdAt ? formatServerDate(item.createdAt) : '',
      comment:
        item.number && item.date
          ? `№ ${item.number} от ${formatServerDate(item.date)}`
          : '',
      author: (
        <RiskMapAuthor children={item.author} noAuthorLink={noAuthorLink} />
      ),
      file: <FileComponent children={item.file} />,
    })
  }

  return (
    <Wrapper id={`versions-history-tab-content-${index}`}>
      <Table columns={COLUMNS} data={tableData} isLoading={isLoading} />
    </Wrapper>
  )
}

export default VersionsHistory
