import { FC } from 'react'
import * as React from 'react'
import { isNumber } from 'lodash'
import RiskMapModal from '@modals/RiskMapModal'
import EditButton from '@organisms/EditButton'
import EditModal from '@templates/EditModal'
import { RiskMapDetailed } from '@models/riskMap'
import { useAppDispatch } from '@hooks/useAppDispatch'
import {
  setMeresIsVisibleIndex,
  setProtectionMeansIsVisibleIndex,
} from '@services/riskMapModal'

const TIMEOUT = 1000

type Props = {
  riskMap: RiskMapDetailed
  dangersIndex?: number
  meresIndex?: number
  protectionMeansIndex?: number
}

const EditRiskMapButton: FC<Props> = ({
  riskMap,
  dangersIndex = 0,
  meresIndex,
  protectionMeansIndex,
}) => {
  const dispatch = useAppDispatch()

  return (
    <EditModal
      ModalContent={RiskMapModal}
      modalProps={{
        riskMap,
        initialActiveTopTab: 1,
        onMount: () => {
          setTimeout(() => {
            document
              .querySelector(`#risk-map-danger-fields-${dangersIndex}`)
              ?.scrollIntoView({ behavior: 'smooth' })

            if (isNumber(meresIndex)) {
              setTimeout(() => {
                dispatch(setMeresIsVisibleIndex(dangersIndex))

                setTimeout(() => {
                  const element = document.querySelector(
                    `#dangers-mere-${meresIndex}`
                  )
                  element?.scrollIntoView({ behavior: 'smooth' })
                }, TIMEOUT)
              }, TIMEOUT)
            }

            if (isNumber(protectionMeansIndex)) {
              setTimeout(() => {
                dispatch(setProtectionMeansIsVisibleIndex(dangersIndex))

                setTimeout(() => {
                  const element = document.querySelector(
                    `#protection-means-${protectionMeansIndex}`
                  )
                  element?.scrollIntoView({ behavior: 'smooth' })
                }, TIMEOUT)
              }, TIMEOUT)
            }
          }, TIMEOUT)
        },
      }}
      CustomEditComponent={({ onClick }) => (
        <EditButton onClick={onClick}> </EditButton>
      )}
      withoutCloseOnOutsideClick
    />
  )
}

export default EditRiskMapButton
