import { FC, useEffect, useState } from 'react'
import BackButton from '@organisms/BackButton'
import NextButton from '@organisms/NextButton'
import { PageWrapper, Wrapper } from './styled'

type Props = {
  itemsCount: number
  perPage?: number
  onChangePagination?: (offset: number) => void
  scrollToElementId?: string
}

const Pagination: FC<Props> = ({
  itemsCount,
  perPage = 5,
  onChangePagination,
  scrollToElementId,
}) => {
  const pages: number[] = []
  for (let i = 1; i <= Math.ceil(itemsCount / perPage); i++) {
    pages.push(i)
  }

  const [selected, setSelected] = useState(1)

  const handleClickBack = () => {
    setSelected((prev) => prev - 1)
  }

  const handleClickNext = () => {
    setSelected((prev) => prev + 1)
  }

  const handlePrevDotsClick = () => {
    setSelected((prev) => prev - 3)
  }

  const handleNextDotsClick = () => {
    setSelected((prev) => prev + 3)
  }

  const handleFirstClick = () => {
    setSelected(1)
  }

  const handleLastClick = () => {
    setSelected(pages.at(-1)!)
  }

  const pagesToRender = pages.filter(
    (item) => item >= selected - 2 && item <= selected + 2
  )

  useEffect(() => {
    if (scrollToElementId) {
      document
        .querySelector(`#${scrollToElementId}`)
        ?.scrollIntoView({ behavior: 'smooth' })
    }

    if (onChangePagination) {
      onChangePagination(selected)
    }
  }, [selected, perPage])

  if (pagesToRender.length === 1) return null

  return (
    <Wrapper>
      {selected > 1 && <BackButton onClick={handleClickBack} />}
      {selected > 3 && (
        <>
          <PageWrapper onClick={handleFirstClick} selected={false}>
            1
          </PageWrapper>
          <PageWrapper onClick={handlePrevDotsClick} selected={false}>
            ...
          </PageWrapper>
        </>
      )}
      {pagesToRender.map((item) => (
        <PageWrapper
          onClick={() => setSelected(item)}
          selected={selected === item}
          key={item}
        >
          {item}
        </PageWrapper>
      ))}
      {selected < pages.length - 2 && (
        <>
          <PageWrapper onClick={handleNextDotsClick} selected={false}>
            ...
          </PageWrapper>
          <PageWrapper onClick={handleLastClick} selected={false}>
            {pages.length}
          </PageWrapper>
        </>
      )}
      {selected < pages.length && <NextButton onClick={handleClickNext} />}
    </Wrapper>
  )
}

export default Pagination
