import { FC, useEffect, useState } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useToggle } from 'react-use'
import { PER_PAGE } from '@const/pagination'
import useFormProcessor from '@hooks/useFormProcessor'
import { FilterState } from '@hooks/useItemsFilters'
import Header, { HeaderProps } from './Header'
import Filters, { FiltersProps } from './Filters'
import { SorterProps, SortStates } from './Filters/Sorter'
import { FilterPanelWrapper } from './styled'

const clearAllFilters = (filters: FilterState): FilterState => {
  const result: FilterState = {}
  for (const key in filters) {
    result[key] = undefined
  }

  return result
}

export type ListPageLayoutListProps = {
  additionalPropsForItem?: any // correct
  ItemComponent?: FC<any> // correct
  list: any[]
  perPage?: number
  perLine?: number
  onBack?: () => void
  isLoading?: boolean
  length: number
  setPage?: (page: number) => void
  onRefresh?: () => void
  scrollToElementId?: string
}

export type ListPageLayoutProps = {
  ListComponent: FC<ListPageLayoutListProps>
  listComponentProps?: any // correct
  methods?: UseFormReturn
  selectedTab?: number
  filters?: FiltersProps
  sorters?: SorterProps[]
  setOrder?: (state: { sortState: SortStates; fieldName: string }) => void
  list: Record<string, unknown>[]
  perPage?: number
  length: number
  setPage?: (page: number) => void
  perLine?: number
  additionalPropsForItem?: any // correct
  initialFiltersState?: any
  scrollToElementId?: string
} & Omit<HeaderProps, 'setFiltersState' | 'onResetOnboarding'>

const ListPageLayout: FC<ListPageLayoutProps> = ({
  ModalContent,
  SecondaryModalContent,
  selectedTab,
  titleTabs,
  filters = [],
  setFilters,
  list,
  perPage = PER_PAGE,
  perLine = 1,
  isLoading = false,
  length,
  setPage,
  sorters,
  setOrder,
  addButtonTitle,
  secondaryAddButtonTitle,
  withSearch,
  additionalPropsForItem,
  reports,
  methods: methodsProp,
  searchPlaceholder,
  initialFiltersState = {},
  reportsOnboarding,
  resetOnboardingCodes,
  ListComponent,
  listComponentProps = {},
  actions,
  scrollToElementId,
  modalWithoutCloseOnOutsideClick,
  secondaryModalWithoutCloseOnOutsideClick,
}) => {
  const methods = useFormProcessor()
  const { setValue } = methodsProp || methods

  const [filtersState, setFiltersState] =
    useState<FilterState>(initialFiltersState)

  const handleClearFiltersState = () => {
    setFilters?.(clearAllFilters)
    setFiltersState(clearAllFilters)

    for (const { fieldName, fieldNameForSubmit } of filters) {
      setValue(fieldName, [])

      if (fieldNameForSubmit) {
        setValue(fieldNameForSubmit, [])
      }
    }
  }

  const [refreshStarted, toggleRefreshStarted] = useToggle(false)

  useEffect(() => {
    if (refreshStarted) {
      setTimeout(toggleRefreshStarted, 300)
    }
  }, [refreshStarted, toggleRefreshStarted])

  const listProps: ListPageLayoutListProps = {
    list,
    perPage,
    perLine,
    isLoading: refreshStarted || isLoading,
    length,
    setPage,
    additionalPropsForItem,
    onRefresh: handleClearFiltersState,
    scrollToElementId,
    ...listComponentProps,
  }

  return (
    <FormProvider {...(methodsProp || methods)}>
      <Header
        actions={actions}
        ModalContent={ModalContent}
        SecondaryModalContent={SecondaryModalContent}
        selectedTab={selectedTab}
        titleTabs={titleTabs}
        isLoading={isLoading}
        addButtonTitle={addButtonTitle}
        secondaryAddButtonTitle={secondaryAddButtonTitle}
        setFilters={setFilters}
        withSearch={withSearch}
        reports={reports}
        setFiltersState={setFiltersState}
        searchPlaceholder={searchPlaceholder}
        reportsOnboarding={reportsOnboarding}
        resetOnboardingCodes={resetOnboardingCodes}
        onResetOnboarding={toggleRefreshStarted}
        modalWithoutCloseOnOutsideClick={modalWithoutCloseOnOutsideClick}
        secondaryModalWithoutCloseOnOutsideClick={
          secondaryModalWithoutCloseOnOutsideClick
        }
      />
      <FilterPanelWrapper id="filter-panel">
        <Filters
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
          sorters={sorters}
          onSort={setOrder}
          initialFiltersState={initialFiltersState}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          handleClearFiltersState={handleClearFiltersState}
        />
      </FilterPanelWrapper>
      <ListComponent {...listProps} />
    </FormProvider>
  )
}

export default ListPageLayout
