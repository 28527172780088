import * as React from 'react'
import { FC } from 'react'
import { Position } from '@models/position'
import FlexWrapper from '@organisms/FlexWrapper'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import CompanyInfoField from '@templates/CompanyInfoField'
import FilialInfoField from '@templates/FilialInfoField'
import DivisionInfoField from '@templates/DivisionInfoField'
import EmployeesCountField from '@templates/EmployeesCountField'
import { Container, Row, StyledTitle, TitleWrapper, Wrapper } from './styled'
import Body from './Body'

const PositionItem: FC<ListItemProps<Position>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item,
  item: { title, id, company, companyBranch, countEmployees = 0, subdivision },
}) => {
  const header: FC<AccordionHeaderProps> = () => {
    return (
      <Wrapper>
        <Container>
          <Row>
            <FlexWrapper>
              <TitleWrapper>
                <StyledTitle mySize="h4">{title}</StyledTitle>
              </TitleWrapper>
            </FlexWrapper>
          </Row>
          <Row>
            <CompanyInfoField company={company} />
            <FilialInfoField filial={companyBranch} />
          </Row>
          <Row>
            <DivisionInfoField division={subdivision} />
            <EmployeesCountField
              countEmployees={countEmployees}
              company={{
                label: company.title,
                value: company['@id'],
              }}
              filial={{
                label: companyBranch.title,
                value: companyBranch['@id'],
              }}
              division={{
                label: subdivision.title,
                value: subdivision['@id'],
              }}
              position={
                item
                  ? {
                      label: item.title,
                      value: item['@id'],
                    }
                  : undefined
              }
            />
          </Row>
        </Container>
      </Wrapper>
    )
  }

  return (
    <Accordion
      skeletonHeight={180}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default PositionItem
