import { File } from '@models/file'
import StoreTags from '@enums/StoreTags'
import { downloadFile } from '@utils/files'
import { BACKEND_URL } from '@const/env'
import emptySplitApi from '../api'

export const filesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postFile: builder.mutation<File, FormData>({
      invalidatesTags: (result) => (result ? [StoreTags.FILES] : []),
      query: (body) => ({
        url: '/api/files/upload',
        method: 'POST',
        body,
      }),
    }),
    getFile: builder.query<void, { path: string; name: string }>({
      query: ({ path, name }) => ({
        url: `/uploads/${path}`,
        responseHandler: downloadFile(name),
      }),
    }),
    getBlobFile: builder.query<string, string>({
      query: (path) => ({
        url: `/uploads/${path}`,
        responseHandler: (response: Response) =>
          response.blob().then((blob) => window.URL.createObjectURL(blob)),
      }),
    }),
    downloadFromApiFiles: builder.query<File, string>({
      query: (path) => ({
        url: `/api/files/${path}`,
      }),
      transformResponse: (response: File) => {
        window.open(`${BACKEND_URL}/uploads/${response.filePath}`, '_blank')
        return response
      },
    }),
  }),
})

export const {
  usePostFileMutation,
  useLazyGetFileQuery,
  useGetBlobFileQuery,
  useLazyDownloadFromApiFilesQuery,
} = filesApi
