/* eslint-disable sonarjs/no-duplicate-string */
import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import Table, { Column } from '@organisms/Table'
import useRiskMapHistories from '@hooks/useRiskMapHistories'
import { RiskMapDetailed } from '@models/riskMap'
import { createInitFilter } from '@utils/filters'
import { formatServerDate } from '@utils/date'
import RiskMapAuthor from '@templates/RiskMapAuthor'
import { Wrapper } from './styled'

const COLUMNS: Column[] = [
  {
    title: 'Действие с картой рисков',
    dataIndex: 'action',
  },
  {
    title: 'Дата действия',
    dataIndex: 'date',
  },
  {
    title: 'Кто сделал',
    dataIndex: 'author',
  },
]

type DataItem = {
  action: string
  date: string
  author: ReactNode
  key: string
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
  noAuthorLink?: boolean
}

const ActionLog: FC<Props> = ({
  onChangeSize,
  riskMap,
  index,
  noAuthorLink = false,
}) => {
  const { data, isLoading } = useRiskMapHistories({
    initFilters: createInitFilter('riskMap', riskMap['@id']),
  })
  const items = data?.items || []
  // const length = data?.length || 0

  useEffect(() => {
    onChangeSize()
  }, [items])

  const tableData: DataItem[] = items.map(
    ({ createdAt, author, description, '@id': idVal }) => ({
      key: idVal,
      action: description,
      date: formatServerDate(createdAt),
      author: <RiskMapAuthor children={author} noAuthorLink={noAuthorLink} />,
    })
  )

  return (
    <Wrapper id={`action-log-tab-content-${index}`}>
      <Table columns={COLUMNS} data={tableData} isLoading={isLoading} />
    </Wrapper>
  )
}

export default ActionLog
