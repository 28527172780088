import * as React from 'react'
import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import {
  useDeleteDivisionMutation,
  useGetDivisionQuery,
} from '@services/divisions'
import { AccordionBodyProps } from '@organisms/Accordion'
import AccordionBodySelectedTabs from '@templates/AccordionBodySelectedTabs'
import EditModal from '@templates/EditModal'
import AccordionBodyFieldsEmployees from '@templates/AccordionBodyFieldsEmployees'
import DivisionModal from '@modals/DivisionModal'
import { FooterWrapper } from '@pages/Divisions/Division/Body/styled'
import DeleteButton from '@templates/DeleteButton'
import PositionListItem from './PositionListItem'

type Props = { id: number } & AccordionBodyProps

const Body: FC<Props> = ({ id, onChangeSize, toggleLoading }) => {
  const [deleteDivision, deleteData] = useDeleteDivisionMutation()
  const { data } = useGetDivisionQuery(id)
  const { companyBranchSubdivisionJobPositions = [], curators } = data || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
    }
  }, [data?.id])

  if (!data) return null

  return (
    <div>
      <AccordionBodyFieldsEmployees
        label="Кураторы подразделения"
        users={curators}
      />
      {companyBranchSubdivisionJobPositions.length > 0 && (
        <AccordionBodySelectedTabs
          onTabChange={onChangeSize}
          title="Должности"
          items={companyBranchSubdivisionJobPositions.map((position) => ({
            id: String(position.id),
            title: position.title,
            children: (
              <PositionListItem id={position.id} onSuccess={onChangeSize} />
            ),
          }))}
        />
      )}
      <FooterWrapper>
        <EditModal
          ModalContent={DivisionModal}
          modalProps={{
            division: data,
          }}
          withoutCloseOnOutsideClick
        />
        <DeleteButton
          {...deleteData}
          onClick={() => deleteDivision(data.id)}
          deleteDescription="Вы собираетесь удалить подразделение! При подтверждении будут удалены и все сотрудники в этом подразделении. Восстановить удаленную информацию будет невозможно! Вы уверены, что хотите удалить ВСЁ подразделение?"
        />
      </FooterWrapper>
    </div>
  )
}

export default Body
