import { FC, MouseEventHandler, ReactNode } from 'react'
import { useToggle } from 'react-use'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import { ButtonsWrapper, TextWrapper } from '@templates/DeleteButton/styled'
import Text from '@organisms/Text'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import Modal from '@organisms/Modal'
import { Wrapper } from '@templates/ButtonWithConfirm/styled'
import Prompt from '@organisms/Prompt'

type Props = {
  ButtonComponent: FC<{ onClick: MouseEventHandler }>
  onConfirm: MouseEventHandler
  confirmText: ReactNode
  confirmButtonText: string
  cancelText: string
  promptText?: string
  withoutCloseOnOutsideClick?: boolean
}

const ButtonWithConfirm: FC<Props> = ({
  ButtonComponent,
  onConfirm,
  confirmText,
  confirmButtonText,
  cancelText,
  promptText,
  withoutCloseOnOutsideClick,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleConfirm: MouseEventHandler = (e) => {
    onConfirm(e)
    toggleVisible(false)
  }

  return (
    <>
      <Wrapper>
        <ButtonComponent
          onClick={(e) => {
            e.stopPropagation()
            toggleVisible(true)
          }}
        />
        {promptText && <Prompt promptText={promptText} width={350} />}
      </Wrapper>
      <Modal
        visible={visible}
        onModalClose={toggleVisible}
        withoutCloseOnOutsideClick={withoutCloseOnOutsideClick}
      >
        <ConfirmModalContent
          title="Подтвердите действие"
          leftIcon="sad-bear-new.svg"
          rightContent={
            <>
              <TextWrapper>
                <Text>{confirmText}</Text>
              </TextWrapper>
              <ButtonsWrapper>
                <PrimaryButton onClick={handleConfirm} big>
                  {confirmButtonText}
                </PrimaryButton>
                <SecondaryButton onClick={toggleVisible} big>
                  {cancelText}
                </SecondaryButton>
              </ButtonsWrapper>
            </>
          }
        />
      </Modal>
    </>
  )
}

export default ButtonWithConfirm
