import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Tabs, { TabsItem } from '@organisms/Tabs'
import { useGetRiskMapVersionsQuery } from '@services/riskMapVersions'
import { createInitFilter } from '@utils/filters'
import { RiskMapDetailed } from '@models/riskMap'
import { formatServerDate } from '@utils/date'
import { RiskMapVersionOption } from '@models/riskMapVersions'
import { Wrapper } from '@pages/RiskMaps/RiskMap/Body/VersionsHistory/styled'
import Title from '@organisms/Title'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import MainInfoItem from './MainInfoItem'

const UI_STATUS = {
  [RiskMapStatuses.DRAFT]: 'Черновик',
  [RiskMapStatuses.APPROVED]: 'Утверждён',
  [RiskMapStatuses.CHANGED]: 'Изменён',
  [RiskMapStatuses.EXTENDED]: 'Продлён',
  [RiskMapStatuses.CANCELED]: 'Отменён',
  [RiskMapStatuses.ARCHIVED]: 'Архив',
}

const getNumberCell = (item: RiskMapVersionOption) => {
  const date =
    item.status === RiskMapStatuses.APPROVED ? item.date : item.createdAt
  const uiStatus = UI_STATUS[item.status]

  if (!date) {
    return uiStatus
  }

  return `${uiStatus} от ${formatServerDate(date)}`
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
}

const Main: FC<Props> = ({ onChangeSize, riskMap, index }) => {
  const { data, isLoading, isSuccess } = useGetRiskMapVersionsQuery({
    ...createInitFilter('riskMap', riskMap['@id']),
    ...createInitFilter('status', [
      RiskMapStatuses.DRAFT,
      RiskMapStatuses.APPROVED,
      RiskMapStatuses.CHANGED,
    ]),
  })
  const items = data?.items || []
  const length = data?.length || 0

  useEffect(() => {
    onChangeSize()
  }, [items])

  const [active, setActive] = useState(0)

  if (isLoading) {
    return (
      <div>
        <Skeleton.Button
          active
          block
          style={{
            height: 50,
            marginBottom: 12,
            borderRadius: 30,
          }}
        />
      </div>
    )
  }

  if (isSuccess && length === 0) {
    return (
      <Wrapper>
        <Title mySize="h4">Версий не найдено</Title>
      </Wrapper>
    )
  }

  const tabs: TabsItem[] = items.reduce((acc, item) => {
    acc.push({
      id: String(item.id),
      title: getNumberCell(item),
      children: <MainInfoItem item={item} riskMap={riskMap} index={index} />,
    })

    return acc
  }, [] as TabsItem[])

  return (
    <Tabs
      items={tabs}
      active={active}
      setActive={setActive}
      tabSize="small"
      onTabChange={onChangeSize}
    />
  )
}

export default Main
