import { calculateFilters } from './filters'

export const downloadFile = (fileName: string) => (response: Response) =>
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  })

export const downloadFileCreator =
  (type: string) => (fileName: string) => (response: Response) => {
    if (!response.ok) return Promise.resolve()

    return response.blob().then((blob) => {
      const blobB = new Blob([blob], {
        type,
      })

      const a = document.createElement('a')
      a.href = URL.createObjectURL(blobB)
      a.download = fileName
      a.click()

      setTimeout(() => {
        a.parentNode?.removeChild(a)
      }, 3000)
    })
  }

export const downloadExcelFile = downloadFileCreator(
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
)

export const downloadPdfFile = downloadFileCreator('application/pdf')
export const downloadDocxFile = downloadFileCreator(
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document; charset=utf-8'
)

export const createDownloadExcelReportQuery =
  (url: string, fileName?: string) =>
  (params: Record<string, Record<string, unknown>>) => ({
    url,
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseHandler: downloadExcelFile(fileName || 'Report.xlsx'),
    params: calculateFilters(params),
  })

export const createDownloadPdfReportQuery =
  (url: string, fileName?: string) =>
  (params: Record<string, Record<string, unknown>>) => ({
    url,
    headers: {
      'Content-Type': 'application/pdf',
    },
    responseHandler: downloadPdfFile(fileName || 'Report.pdf'),
    params: calculateFilters(params),
  })
