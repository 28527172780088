import * as React from 'react'
import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMount, useToggle } from 'react-use'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import {
  dateField,
  employeeField,
  textField,
} from '@templates/AddNewItemModal/templates'
import DeleteButton from '@templates/DeleteButton'
import { Option } from '@organisms/Select'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import useMeasuresKindsField from './useMeasuresKindsField'
import { ButtonsWrapper, Container } from './styled'
import useMeasuresContentsField from './useMeasuresContentsField'
import { getContentName, getMereKindName } from './nameUtils'

const sourceField = (name: string) =>
  textField({
    name,
    label: 'Источник финансирования мероприятий',
    placeholder: 'Источник финансирования мероприятий',
  })

const deadlineField = (name: string) =>
  dateField({
    name,
    label: 'Плановый срок выполнения мероприятий',
  })

const responsibleField = (name: string, selectedCompany: Option) =>
  employeeField({
    name,
    label: 'Ответственное за выполнение мероприятий лицо',
    isDisabled: !selectedCompany,
    initValues: {
      company: selectedCompany,
    },
  })

type Props = {
  id: string
  index: number
  remove: (index: number) => void
  hazard: Option
  event: Option
  company: Option
  isLast: boolean
}

const MeresFieldsBlock: FC<Props> = ({
  id,
  index,
  remove,
  hazard,
  event,
  isLast,
  company,
}) => {
  const [cleanerStarted, toggleCleanerStarted] = useToggle(false)
  const { watch, setValue } = useFormContext()

  const kind = watch(getMereKindName(index))

  const { field: measuresKindsField, setFilters: measuresKindsSetFilters } =
    useMeasuresKindsField({
      name: getMereKindName(index),
      isRequired: true,
    })
  const {
    field: measuresContentsField,
    setFilters: measuresContentsSetFilters,
  } = useMeasuresContentsField({
    name: getContentName(index),
    isDisabled: !kind,
    isRequired: true,
  })

  useMount(() => {
    setTimeout(() => {
      toggleCleanerStarted(true)
    }, 1000)
  })

  useEffect(() => {
    if (!cleanerStarted) return

    setValue(getContentName(index), null)
  }, [kind])

  useEffect(() => {
    measuresKindsSetFilters({
      hazard: hazard?.value || null,
      event: event?.value || null,
    })
  }, [event, hazard])

  useEffect(() => {
    measuresContentsSetFilters({ kinds: kind?.value ? [kind?.value] : null })
  }, [kind])

  return (
    <Container id={`dangers-mere-${index}`} isLast={isLast} key={id}>
      <ExternalLineWrapper>
        <LineFields fields={[measuresKindsField(), measuresContentsField()]} />
      </ExternalLineWrapper>
      <ExternalLineWrapper>
        <LineFields
          fields={[
            sourceField(`meres.${index}.source`),
            deadlineField(`meres.${index}.deadline`),
            responsibleField(`meres.${index}.responsible`, company),
          ]}
        />
      </ExternalLineWrapper>
      <ButtonsWrapper>
        <DeleteButton onClick={() => remove(index)}>Удалить меру</DeleteButton>
      </ButtonsWrapper>
    </Container>
  )
}

export default MeresFieldsBlock
