import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const DesktopRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`
const MobileRow = styled(DesktopRow)`
  flex-direction: column;
`
export const Row = isMobile ? MobileRow : DesktopRow

export const TitleWrapper = styled.div`
  margin-right: 10px;
`

export const EditIcon = styled.div<{ hovered: boolean }>`
  height: 26px;
  width: 26px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ hovered, theme }) =>
    hovered ? theme.colors.main : 'inherit'};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledTitle = styled(Title)`
  white-space: pre-line;
`
