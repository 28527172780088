import * as React from 'react'
import { FC } from 'react'
import { CompanyGroup } from '@models/companyGroup'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import InfoField from '@organisms/InfoField'
import { StyledTitle, Wrapper } from './styled'
import Body from './Body'

const CompanyGroupItem: FC<ListItemProps<CompanyGroup>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item: { id, title, companiesCount },
}) => {
  const header: FC<AccordionHeaderProps> = () => (
    <Wrapper>
      <StyledTitle mySize="h4">{title}</StyledTitle>
      <InfoField label="Количество компаний" value={companiesCount} />
    </Wrapper>
  )

  return (
    <Accordion
      skeletonHeight={120}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default CompanyGroupItem
