export const dangersControlMeasureWorkplaceOptions = [
  {
    value: 'office',
    label: 'В офисе',
  },
  {
    value: null,
    label: 'Не в офисе',
  },
]
