import { FC } from 'react'
import { useToggle } from 'react-use'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from '@organisms/Modal'

type Props = {
  withoutCloseOnOutsideClick?: boolean
  Component: FC<{
    handleCloseModal: () => void
  }>
}

const ModalOnRoute: FC<Props> = ({ Component, withoutCloseOnOutsideClick }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [isVisible, toggleIsVisible] = useToggle(true)

  const handleClose = () => {
    toggleIsVisible(false)
  }

  const handleCloseClick = () => {
    navigate(state.backgroundLocation?.pathname || '/')
  }

  return (
    <Modal
      visible={isVisible}
      onModalClose={handleClose}
      onCloseClick={handleCloseClick}
      withoutCloseOnOutsideClick={withoutCloseOnOutsideClick}
    >
      <Component handleCloseModal={handleClose} />
    </Modal>
  )
}

export default ModalOnRoute
