import React, { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPageLayout from '@templates/ListPageLayout'
import WorkPlacesTable from '@pages/WorkPlaces/WorkPlacesTable'
import AddButton from '@templates/AddButton'
import ButtonStyle from '@enums/ButtonStyle'
import JobConditionAssessmentsFromFileXlsModal from '@modals/JobConditionAssessmentsFromFileXlsModal'
import JobConditionAssessmentsFromFileXmlModal from '@modals/JobConditionAssessmentsFromFileXmlModal'
import GrayButton from '@organisms/GrayButton'
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import WorkPlaceArchive from '@modals/WorkPlaceArchive'
import CreateWorkPlaceModal from '@modals/CreateWorkPlaceModal'
import useWorkPlaces from '@hooks/useWorkPlaces'
import useWorkPlacesFilters from './useWorkPlacesFilters'

const WorkPlaces: FC = () => {
  const methods = useFormProcessor()
  const filters = useWorkPlacesFilters(methods)
  const { data, ...rest } = useWorkPlaces()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPageLayout
      {...rest}
      titleTabs={[
        {
          title: 'Рабочие места',
          counter: length,
          index: 0,
        },
      ]}
      actions={
        <>
          <AddButton
            ModalContent={CreateWorkPlaceModal}
            buttonStyle={ButtonStyle.GRAY}
            withoutCloseOnOutsideClick
          />
          <AddButton
            ModalContent={JobConditionAssessmentsFromFileXlsModal}
            buttonStyle={ButtonStyle.GRAY}
            addButtonTitle="Добавить из .XLS"
          />
          <AddButton
            ModalContent={JobConditionAssessmentsFromFileXmlModal}
            buttonStyle={ButtonStyle.GRAY}
            addButtonTitle="Добавить из .XML"
          />
          <AddButton
            leftIcon={<InboxOutlined />}
            addButtonTitle="Архивировать просроченные"
            buttonStyle={ButtonStyle.GRAY}
            ModalContent={WorkPlaceArchive}
          />
          <GrayButton leftIcon={<DownloadOutlined />}>Скачать отчет</GrayButton>
        </>
      }
      methods={methods}
      filters={filters}
      ListComponent={WorkPlacesTable}
      list={items}
      length={length}
      withSearch
      modalWithoutCloseOnOutsideClick
    />
  )
}

export default WorkPlaces
