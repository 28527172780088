import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { RiskMapVersionAuthor } from '@models/riskMapVersions'
import { ReactComponent as BlackRound } from '@interface-images/black-round.svg'
import { Routes } from '@enums/Routes'
import Link, { LinkStyle } from '@organisms/Link'
import { AuthorWrapper, Label, SubWrapper } from './styled'

type Props = {
  children: RiskMapVersionAuthor
  noAuthorLink?: boolean
}

const RiskMapAuthor: FC<Props> = ({ children, noAuthorLink = false }) => {
  const { id, company, companyBranch, jobPosition, subdivision, fullName } =
    children

  const navigate = useNavigate()

  const handleClickOnLink = () => {
    navigate(Routes.EMPLOYEES + `?id=${id}`)
  }

  return (
    <AuthorWrapper>
      {company && (
        <SubWrapper>
          <Label mySize="h4">{company.title}</Label>
          <BlackRound />
        </SubWrapper>
      )}
      {companyBranch && (
        <SubWrapper>
          <Label mySize="h4">{companyBranch.title}</Label>
          <BlackRound />
        </SubWrapper>
      )}
      {subdivision && (
        <SubWrapper>
          <Label mySize="h4">{subdivision.title}</Label>
          <BlackRound />
        </SubWrapper>
      )}
      {jobPosition && (
        <SubWrapper>
          <Label mySize="h4">{jobPosition.title}</Label>
          <BlackRound />
        </SubWrapper>
      )}
      {noAuthorLink ? (
        <Label mySize="h4">{fullName}</Label>
      ) : (
        <Link
          onClick={handleClickOnLink}
          text={fullName}
          linkStyle={LinkStyle.DEFAULT}
        />
      )}
    </AuthorWrapper>
  )
}

export default RiskMapAuthor
