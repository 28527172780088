import { FC } from 'react'
import useDivisions from '@hooks/useDivisions'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPage from '@templates/ListPage'
import { ALPHABETICAL_SORTER } from '@const/sorters'
import DivisionModal from '@modals/DivisionModal'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import Division from './Division'
import useDivisionFilters from './useDivisionFilters'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

const Divisions: FC = () => {
  const methods = useFormProcessor()
  const filters = useDivisionFilters(methods)
  const { data, ...rest } = useDivisions()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.subdivision_section}
      />
      <ListPage
        {...rest}
        methods={methods}
        ModalContent={DivisionModal}
        titleTabs={[
          {
            title: 'Подразделения',
            counter: length,
            index: 0,
          },
        ]}
        filters={filters}
        sorters={[ALPHABETICAL_SORTER]}
        ItemComponent={Division}
        list={items}
        length={length}
        withSearch
        resetOnboardingCodes={[
          OnboardingCodes.subdivision_section,
          OnboardingCodes.subdivision_create,
        ]}
        modalWithoutCloseOnOutsideClick
      />
    </>
  )
}

export default Divisions
