import React, { FC } from 'react'
import { useToggle } from 'react-use'
import RepeatButton from '@organisms/RepeatButton'
import Modal from '@organisms/Modal'
import { RiskMapDetailed } from '@models/riskMap'
import Prompt from '@organisms/Prompt'
import UpdateAllModal from './UpdateAllModal'

type Props = {
  riskMap: RiskMapDetailed
}

const UpdateAll: FC<Props> = ({ riskMap }) => {
  const [confirmModalIsVisible, toggleConfirmModalIsVisible] = useToggle(false)

  return (
    <>
      <RepeatButton onClick={toggleConfirmModalIsVisible}>
        Обновить все СИЗ
      </RepeatButton>
      <Prompt
        promptText="При клике все даты “Обновить до” будут пересчитаны по формуле: дата обновления + периодичность обновления (месяцев)"
        width={300}
      />
      <Modal visible={confirmModalIsVisible}>
        <UpdateAllModal
          riskMap={riskMap}
          handleCloseModal={toggleConfirmModalIsVisible}
        />
      </Modal>
    </>
  )
}

export default UpdateAll
