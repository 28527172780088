import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const Footer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.sizes.twoBase};
`

export const Wrapper = styled.div`
  width: 100%;
`

export const TableWrapper = styled.div`
  margin-top: 30px;
`

export const StyledTitle = styled(Title)`
  margin-bottom: 15px;
`
