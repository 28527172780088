import { FC } from 'react'
import { WorkPlaceOption } from '@models/workPlace'
import { Tab } from '@organisms/Tabs'
import GreenYellowStatus from '@templates/GreenYellowStatus'
import { Column, Row, Wrapper } from './styled'

type Props = {
  item: WorkPlaceOption
}

const SoutItem: FC<Props> = ({ item }) => {
  return (
    <Wrapper>
      <Column>
        <span>РАБОЧЕЕ МЕСТО</span>
        <Tab
          tabSize="small"
          title="200 менеджер"
          id={item['@id']}
          withoutHover
          isWhiteBackground
        />
      </Column>
      <Column>
        <span>Карта СОУТ № 1</span>
        <span>Дата карты 01.01.1970</span>
      </Column>
      <Row>
        <span>Дата ознакомления</span>
        <Tab
          tabSize="small"
          title="01.01.1970"
          id={item['@id']}
          withoutHover
          isWhiteBackground
        />
      </Row>
      <GreenYellowStatus
        success
        successText="Ознакомлен"
        notSuccessText="Не ознакомлен"
      />
    </Wrapper>
  )
}

export default SoutItem
