import * as React from 'react'
import { FC, memo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '@hooks/useAppSelector'
import AddButton from '@organisms/AddButton'
import { FormValues } from '@modals/RiskMapModal'
import { DEFAULT_OPTIONS } from '@modals/RiskMapModal/getDefaultValues'
import { memoEqual } from '@utils/memo'
import useEffectByValue from '@hooks/useEffectByValue'
import { useAppDispatch } from '@hooks/useAppDispatch'
import {
  updateSelectedMeresForms,
  updateSelectedProtectionMeansForms,
} from '@services/riskMapModal'
import { MereFormItem } from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/MeresFields'
import { ProtectionMeanItem } from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { AddButtonWrapper, Container } from './styled'
import DangersFieldsBlock from './DangersFieldsBlock'

const DangersFields: FC = () => {
  const dispatch = useAppDispatch()

  const selectDangersModalIsVisible = useAppSelector(
    (state) => state.riskMapModal.selectDangersModalIsVisible
  )
  const selectedDangers = useAppSelector(
    (state) => state.riskMapModal.selectedDangers
  )
  const { control, setValue, getValues } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: 'dangerDTOs',
    control,
  })

  useEffectByValue(() => {
    if (
      selectDangersModalIsVisible ||
      !selectedDangers ||
      selectedDangers.length === 0
    ) {
      return
    }

    const dtosState = (getValues() as FormValues).dangerDTOs || []
    const filledFields = dtosState.filter(
      ({ workPlace, selectedFromModal }) =>
        !selectedFromModal && !!workPlace?.value
    )
    const fieldsFromDangersModal = selectedDangers.map((selectedDanger) => {
      return {
        selectedFromModal: true,
        ...(selectedDanger.workPlace
          ? {
              workPlace: {
                value: selectedDanger.workPlace['@id'],
                label: selectedDanger.workPlace.value,
              },
            }
          : {}),
        ...(selectedDanger.kind
          ? {
              kind: {
                value: selectedDanger.kind['@id'],
                label: selectedDanger.kind.value,
              },
            }
          : {}),
        ...(selectedDanger.hazard
          ? {
              hazard: {
                value: selectedDanger.hazard['@id'],
                label: selectedDanger.hazard.value,
              },
            }
          : {}),
        ...(selectedDanger.event
          ? {
              event: {
                value: selectedDanger.event['@id'],
                label: selectedDanger.event.value,
              },
            }
          : {}),
        ...(selectedDanger.object
          ? {
              object: {
                value: selectedDanger.object['@id'],
                label: selectedDanger.object.value,
              },
            }
          : {}),
        ...DEFAULT_OPTIONS,
      }
    })
    const allFields = [...filledFields, ...fieldsFromDangersModal]
    setValue('dangerDTOs', allFields)
  }, [selectedDangers, selectDangersModalIsVisible])

  const handleAdd = () => {
    dispatch(
      updateSelectedMeresForms({
        index: fields.length,
        value: {
          prefilled: false,
          notAutoAddControlMeasures: false,
          meres: [{} as MereFormItem],
        },
      })
    )
    dispatch(
      updateSelectedProtectionMeansForms({
        index: fields.length,
        value: {
          prefilled: false,
          notAutoAddProtectionMeans: false,
          protectionMeans: [{} as ProtectionMeanItem],
        },
      })
    )
    append(DEFAULT_OPTIONS)
  }

  return (
    <Container>
      {fields.map((field, index, array) => (
        <>
          {window.ENABLE_LOGS && <h1>{index + 1}</h1>}
          <DangersFieldsBlock
            key={field.id}
            index={index}
            id={field.id}
            remove={remove}
            isLast={index === array.length - 1}
          />
        </>
      ))}
      <AddButtonWrapper>
        <AddButton id="risk-map-danger-add" onClick={handleAdd}>
          Добавить опасность
        </AddButton>
      </AddButtonWrapper>
    </Container>
  )
}

export default memo(DangersFields, memoEqual)
