import { FC } from 'react'
import { formatServerDate } from '@utils/date'
import { EmployeeEducation } from '@models/employeeEducation'
import { ListItemProps } from '@templates/List'
import AccordionBodyField from '@organisms/AccordionBodyField'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import Body from './Body'
import { StyledTitle, TitleWrapper, Wrapper } from './styled'
import ReportButtons from './ReportButtons'

const EmployeesEducationItem: FC<ListItemProps<EmployeeEducation>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item: { id, title, course, deadlineAt, createdAt },
  index,
}) => {
  const header: FC<AccordionHeaderProps> = () => (
    <Wrapper>
      <TitleWrapper>
        <StyledTitle mySize="h4">{title}</StyledTitle>
      </TitleWrapper>
      {course && (
        <AccordionBodyFieldWithTabs
          withoutFixedHeight
          label="курс"
          items={[
            {
              id: '1',
              title: course?.title,
            },
          ]}
        />
      )}
      <AccordionBodyField
        label={`дата назначения ${
          deadlineAt ? ' / рекомендуемая дата окончания' : ''
        }`}
        value={
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          `${formatServerDate(createdAt)}${
            deadlineAt ? ` / ${formatServerDate(deadlineAt)}` : ''
          }`
        }
      />
      <ReportButtons educationId={id} />
    </Wrapper>
  )

  return (
    <Accordion
      skeletonHeight={145}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
          index={index}
        />
      )}
    />
  )
}

export default EmployeesEducationItem
