import { Option } from '@organisms/Select'
import { BaseApiResponse, CommonQueryParams, QueryType } from './common'
import { CourseCategoryServerResponse } from './courseCategory'
import { Company } from './company'
import { Filial } from './filial'

export enum CourseTypes {
  TRAINING = 'training',
  PROFESSIONAL_RETRAINING = 'professionalRetraining',
  MINI = 'mini',
  LABOR_PROTECTION_TRAINING = 'laborProtectionTraining',
}

export enum CourseSubTypes {
  COURSE = 'course',
  DEMO = 'demo',
  INSTRUCTION = 'briefing',
}

export type CourseServerResponse = {
  chaptersCount: number
  listenerCourseProgressPercent: number
  courseThemesCount: number
  listenersCount: number
  courseChapters: string[]
  courseGroups: string[]
  id: number
  title: string
  subType: CourseSubTypes
  type: CourseTypes
  published: boolean
  hoursCount: number
  descriptionLink: string | null
  courseCategory: {
    id: string
    title: string
  }
  deadlineAt: string | null
  listenerCourseGroup: ListenerCourseGroup
  safeProgram: boolean
  company?: {
    id: number
    title: string
    description: string
  } & BaseApiResponse
  companyBranch?: {
    id: number
    title: string
  } & BaseApiResponse
} & BaseApiResponse

export type ListenerCourseGroup = {
  createdAt: string
  deadlineAt: string
  id: number
  listenersCount: number
  title: string
} & BaseApiResponse

export type Course = CourseServerResponse & Option & BaseApiResponse

export type CourseDetailed = {
  id: number
  title: string
  type: CourseTypes
  subType: string
  hoursCount: number
  published: boolean
  courseCategory: CourseCategoryServerResponse
  descriptionLink: string | null
  free: boolean
  safeProgram: boolean
  company?: Company
  companyBranch?: Filial
  ministryLaborId: string | null
  electricalSafetyFirstGroup: boolean
} & BaseApiResponse

export type CourseQueryParams = {
  courseCategory?: QueryType
  forListener?: QueryType
  forCurator?: QueryType
  type?: QueryType
  subType?: QueryType
  published?: boolean
} & CommonQueryParams

export type CommonCourseBody = {
  title: string
  type: string
  subType: string | undefined
  hoursCount: number
  published: boolean
  courseCategory: string
  descriptionLink: string | undefined
  free: boolean
  safeProgram: boolean
  company: string | null
  companyBranch: string | null
  ministryLaborId: string | undefined
  electricalSafetyFirstGroup: boolean
}

export type CreateCourseBody = CommonCourseBody
export type UpdateCourseBody = CommonCourseBody & {
  id: number
}
