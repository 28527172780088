import { FC } from 'react'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useFormProcessor from '@hooks/useFormProcessor'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import { useArchiveExpiredJobConditionAssessmentsMutation } from '@services/jobConditionAssesments'

const WorkPlaceArchive: FC = () => {
  const [archive, data] = useArchiveExpiredJobConditionAssessmentsMutation()

  const methods = useFormProcessor()

  const handleSubmit = () => {
    void archive()
  }

  return (
    <AddNewItemModalLayout
      {...data}
      methods={methods}
      onSubmitForm={handleSubmit}
      customFullTitle="Архивировать просроченные карты"
      saveText="Архивировать"
    >
      <DescriptionWrapper>
        <b>Просроченных карт СОУТ: TODO</b>
        Срок действия карты СОУТ: 5 лет.
        <br />
        Архивировать просроченные карты?
      </DescriptionWrapper>
    </AddNewItemModalLayout>
  )
}

export default WorkPlaceArchive
