import { FC, useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  CommonJobConditionAssessmentBody,
  JobConditionAssessmentWarrantyTypeOption,
} from '@models/jobConditionAssessment'
import {
  useCreateJobConditionAssessmentMutation,
  useGetJobConditionAssessmentsWarrantyTypesQuery,
  useUpdateJobConditionAssessmentMutation,
} from '@services/jobConditionAssesments'
import Tabs, { TabsItem } from '@organisms/Tabs'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import { TopTabsWrapper } from '@modals/RiskMapModal/styled'
import { hasValidationErrors } from '@utils/validation'
import { File } from '@models/file'
import { Option } from '@organisms/Select'
import {
  ClassesFields,
  MainFields,
} from '@modals/JobConditionAssessmentModal/enums'
import Loader from '@organisms/Loader'
import { WorkPlaceDetailed } from '@models/workPlace'
import Main from './Main'
import Classes from './Classes'
import Warranties from './Warranties'
import getDefaultValues from './getDefaultValues'

export type FormValues = {
  cardNumber: string
  date: string
  file?: File
  workConditionsImprovementRecommendations?: string
  workAndRestRegimeRecommendations?: string
  employeeSelectionRecommendations?: string
  workplaceNumber: string
  company?: Option
  filial?: Option
  division?: Option
  position?: Option
  title?: string
  code?: string
  similarWorkplacesCountAndNumbers?: string
  etksEksEdition?: string
  usedProductionEquipment?: string
  usedMaterialsAndRaw?: string
  countOnWorkplace?: string
  countOnSimilarWorkplaces?: string
  womenCount?: string
  under18Count?: string
  disabledCount?: string
  chemicalClass?: Option
  biologyClass?: Option
  aerosolsFactor?: Option
  noiseFactor?: Option
  infrasoundFactor?: Option
  ultrasoundAirFactor?: Option
  generalVibrationFactor?: Option
  localVibrationFactor?: Option
  nonIonizingRadiationFactor?: Option
  ionizingRadiationFactor?: Option
  microclimateParametersFactor?: Option
  lightEnvironmentParametersFactor?: Option
  laborProcessSeverityFactor?: Option
  laborProcessTensionFactor?: Option
  workingConditionFinalClass?: Option
  jobConditionAssessmentWarranties: {
    title: string
    code: string
    required: boolean
    actual: boolean
    reason: string
  }[]
}

type Props = {
  isEdit?: boolean
  workPlace?: WorkPlaceDetailed
  handleCloseModal: () => void
}

const JobConditionAssessmentModal: FC<
  Props & {
    jobConditionAssessmentWarranties: JobConditionAssessmentWarrantyTypeOption[]
  }
> = ({
  isEdit = false,
  workPlace,
  handleCloseModal,
  jobConditionAssessmentWarranties,
}) => {
  const [active, setActive] = useState(0)

  const [post, postData] = useCreateJobConditionAssessmentMutation()
  const [put, putData] = useUpdateJobConditionAssessmentMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(
      workPlace,
      jobConditionAssessmentWarranties
    ),
  })

  const declared = methods.watch('declared')
  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonJobConditionAssessmentBody = {
      cardNumber: form.cardNumber,
      date: form.date,
      file: form.file?.['@id'],
      workConditionsImprovementRecommendations:
        form.workConditionsImprovementRecommendations,
      workAndRestRegimeRecommendations: form.workAndRestRegimeRecommendations,
      employeeSelectionRecommendations: form.employeeSelectionRecommendations,
      workPlace: {
        workplaceNumber: form.workplaceNumber,
        company: form.company?.value,
        companyBranch: form.filial?.value,
        subdivision: form.division?.value,
        jobPosition: form.position?.value,
        title: form.title,
        code: form.code,
        similarWorkplacesCountAndNumbers: form.similarWorkplacesCountAndNumbers,
        declared: !!declared,
        etksEksEdition: form.etksEksEdition,
        usedProductionEquipment: form.usedProductionEquipment,
        usedMaterialsAndRaw: form.usedMaterialsAndRaw,
        countOnWorkplace: Number(form.countOnWorkplace),
        countOnSimilarWorkplaces: Number(form.countOnSimilarWorkplaces),
        womenCount: Number(form.womenCount),
        under18Count: Number(form.under18Count),
        disabledCount: Number(form.disabledCount),
      },
      chemicalClass: form.chemicalClass?.value,
      biologyClass: form.biologyClass?.value,
      aerosolsFactor: form.aerosolsFactor?.value,
      noiseFactor: form.noiseFactor?.value,
      infrasoundFactor: form.infrasoundFactor?.value,
      ultrasoundAirFactor: form.ultrasoundAirFactor?.value,
      generalVibrationFactor: form.generalVibrationFactor?.value,
      localVibrationFactor: form.localVibrationFactor?.value,
      nonIonizingRadiationFactor: form.nonIonizingRadiationFactor?.value,
      ionizingRadiationFactor: form.ionizingRadiationFactor?.value,
      microclimateParametersFactor: form.microclimateParametersFactor?.value,
      lightEnvironmentParametersFactor:
        form.lightEnvironmentParametersFactor?.value,
      laborProcessSeverityFactor: form.laborProcessSeverityFactor?.value,
      laborProcessTensionFactor: form.laborProcessTensionFactor?.value,
      workingConditionFinalClass: form.workingConditionFinalClass?.value,
      jobConditionAssessmentWarranties:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        form.jobConditionAssessmentWarranties.map(({ title, ...item }) => item),
    }

    if (isEdit) {
      if (workPlace) {
        put({
          ...commonForm,
          id: workPlace.jobConditionAssessment.id,
        })
      }
    } else {
      post(commonForm)
    }
  }
  const topTabs: TabsItem[] = [
    {
      title: 'Основные',
      badgeColor: hasValidationErrors(
        methods.formState.errors,
        Object.values(MainFields)
      )
        ? 'red'
        : undefined,
      children: <Main />,
      id: '1',
    },
    {
      title: 'Классы',
      badgeColor: hasValidationErrors(
        methods.formState.errors,
        Object.values(ClassesFields)
      )
        ? 'red'
        : undefined,
      children: <Classes />,
      id: '2',
    },
    {
      title: 'Гарантии и компенсации',
      badgeColor: hasValidationErrors(
        methods.formState.errors,
        jobConditionAssessmentWarranties.map(({ code }) => code)
      )
        ? 'red'
        : undefined,
      children: <Warranties />,
      id: '3',
    },
  ]

  return (
    <AddNewItemModalLayout
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      titlePostfix="рабочее место"
      withStopPropagation
    >
      <TopTabsWrapper>
        <Tabs
          withNoUnmount
          items={topTabs}
          active={active}
          setActive={setActive}
          tabSize="small"
        />
      </TopTabsWrapper>
    </AddNewItemModalLayout>
  )
}

const JobConditionAssessmentModalWithData: FC<Props> = (props) => {
  const { data, isLoading } = useGetJobConditionAssessmentsWarrantyTypesQuery()
  const jobConditionAssessmentWarranties = data?.items || []

  if (isLoading) {
    return <Loader isLoading />
  }

  return (
    <JobConditionAssessmentModal
      {...props}
      jobConditionAssessmentWarranties={jobConditionAssessmentWarranties}
    />
  )
}

export default JobConditionAssessmentModalWithData
