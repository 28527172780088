import * as React from 'react'
import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMount, useToggle } from 'react-use'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import DeleteButton from '@templates/DeleteButton'
import { Option } from '@organisms/Select'
import {
  dateField,
  numberField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Tab } from '@organisms/Tabs'
import { ProtectionMeanConstructionOption } from '@models/protectionMeans'
import { ProtectionMeanItem } from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { ProtectionMeanAdditionalType } from '@enums/ProtectionMeanAdditionalType'
import { BadgeWrapper, ButtonsWrapper, Container } from './styled'
import useProtectionMeanTypesField from './useProtectionMeanTypesField'
import useProtectionMeanConstructionsField from './useProtectionMeanConstructionsField'
import {
  getAdditionalTypeName,
  getConstructionName,
  getCountName,
  getEtnNumberName,
  getHasEqualConstructionsName,
  getPeriodName,
  getProtectionMeanTypeName,
  getUpdateBeforeName,
} from './nameUtils'

const etnNumberField = (name: string) =>
  textField({
    name,
    label: '№ п/п ЕТН',
    placeholder: '№ п/п ЕТН',
  })

const countField = (name: string) =>
  textField({
    name,
    label: 'Количество (штуки, пары, комплекты, мл)',
    placeholder: 'Количество',
  })

const periodField = (name: string) =>
  numberField({
    name,
    label: 'Периодичность обновления (мес.)',
    placeholder: 'Количество',
  })

const updateBeforeField = (name: string) =>
  dateField({
    name,
    label: 'Обновить до',
  })

type Props = {
  field: ProtectionMeanItem
  id: string
  index: number
  remove: (index: number) => void
  event: Option
  isLast: boolean
  onSelectConstruction: (
    construction: ProtectionMeanConstructionOption | null,
    field: ProtectionMeanItem,
    index: number
  ) => void
  onDeleteProtectionMean: (field: ProtectionMeanItem) => void
}

const ProtectionMeansFieldsBlock: FC<Props> = ({
  field,
  id,
  index,
  remove,
  event,
  isLast,
  onSelectConstruction,
  onDeleteProtectionMean,
}) => {
  const [cleanerStarted, toggleCleanerStarted] = useToggle(false)
  const { watch, setValue } = useFormContext()

  const construction = watch(getConstructionName(index))
  const type = watch(getProtectionMeanTypeName(index))
  const hasEqualConstructions = watch(getHasEqualConstructionsName(index))
  const additionalType = watch(getAdditionalTypeName(index))

  const {
    field: protectionMeanTypesField,
    setFilters: protectionMeanTypesSetFilters,
  } = useProtectionMeanTypesField({
    name: getProtectionMeanTypeName(index),
    isRequired: true,
  })
  const {
    field: protectionMeanConstructionField,
    setFilters: protectionMeanConstructionSetFilters,
  } = useProtectionMeanConstructionsField(
    {
      name: getConstructionName(index),
      isDisabled: !type,
      isRequired: true,
    },
    (construction) => onSelectConstruction(construction, field, index)
  )

  useMount(() => {
    setTimeout(() => {
      toggleCleanerStarted(true)
    }, 500)
  })

  useEffect(() => {
    protectionMeanTypesSetFilters({ riskMapDangerEvent: event?.value || null })
  }, [event])

  useEffect(() => {
    protectionMeanConstructionSetFilters({
      type: type?.['@id'] || null,
      'riskMapDangerEventProtectionMeans.event': event?.value || null,
    })
    if (cleanerStarted && construction) {
      setValue(getConstructionName(index), null)
      onDeleteProtectionMean(field)
    }
  }, [type, event])

  const handleDelete = () => {
    onDeleteProtectionMean(field)
    remove(index)
  }

  return (
    <Container id={`protection-means-${index}`} isLast={isLast} key={id}>
      <ExternalLineWrapper>
        <LineFields
          fields={[
            protectionMeanTypesField(),
            protectionMeanConstructionField(),
            etnNumberField(getEtnNumberName(index)),
          ]}
        />
      </ExternalLineWrapper>
      <ExternalLineWrapper>
        <LineFields
          fields={[
            countField(getCountName(index)),
            periodField(getPeriodName(index)),
            updateBeforeField(getUpdateBeforeName(index)),
          ]}
        />
      </ExternalLineWrapper>
      {hasEqualConstructions && (
        <BadgeWrapper>
          <Tab
            id="id"
            title={
              'Есть аналогичные конструкции, весь список в поле “Конструкция”'
            }
            tabSize="small"
            badgeColor="orange"
            withoutHover
          />
        </BadgeWrapper>
      )}
      {additionalType && (
        <BadgeWrapper>
          <Tab
            id="id"
            title={`Дополнительное СИЗ${
              additionalType ===
              ProtectionMeanAdditionalType.ADDITIONAL_TO_PREVIOUS
                ? ' к предыдущему'
                : ''
            }`}
            tabSize="small"
            badgeColor="orange"
            withoutHover
          />
        </BadgeWrapper>
      )}
      <ButtonsWrapper>
        <DeleteButton onClick={handleDelete}>Удалить СИЗ</DeleteButton>
      </ButtonsWrapper>
    </Container>
  )
}

export default ProtectionMeansFieldsBlock
