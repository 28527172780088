import { FC } from 'react'
import ListPage from '@templates/ListPage'
import RiskMapModal from '@modals/RiskMapModal'
import useFormProcessor from '@hooks/useFormProcessor'
import RiskMapModalFromFile from '@modals/RiskMapModalFromFile'
import useRiskMaps from '@hooks/useRiskMaps'
import OnboardingCodes from '@enums/OnboardingCodes'
import Onboarding from '@templates/Onboarding'
import useRiskMapsReports from './useRiskMapsReports'
import RiskMap from './RiskMap'
import useRiskMapsFilters from './useRiskMapsFilters'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'
import {
  REPORTS_ONBOARDING_STEPS,
  REPORTS_ONBOARDING_TITLE,
} from './reportsOnboarding'

const RiskMaps: FC = () => {
  const methods = useFormProcessor()
  const filters = useRiskMapsFilters(methods)
  const reports = useRiskMapsReports()
  const { data, ...rest } = useRiskMaps()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.risk_map_section}
      />
      <ListPage
        {...rest}
        reports={reports}
        methods={methods}
        filters={filters}
        titleTabs={[
          {
            title: 'Карты рисков',
            counter: length,
            index: 0,
          },
        ]}
        ModalContent={RiskMapModal}
        SecondaryModalContent={RiskMapModalFromFile}
        secondaryAddButtonTitle="Добавить из .xlsx"
        ItemComponent={RiskMap}
        list={items}
        length={length}
        withSearch
        resetOnboardingCodes={[
          OnboardingCodes.risk_map_section,
          OnboardingCodes.risk_map_create,
          OnboardingCodes.risk_map_open_card,
          OnboardingCodes.risk_map_reports,
        ]}
        reportsOnboarding={{
          firstStepTitle: REPORTS_ONBOARDING_TITLE,
          steps: REPORTS_ONBOARDING_STEPS,
          code: OnboardingCodes.risk_map_reports,
        }}
        modalWithoutCloseOnOutsideClick
      />
    </>
  )
}

export default RiskMaps
