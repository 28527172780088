/* eslint-disable no-unused-expressions, no-sequences */
import { isEmpty } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import {
  MereFormItem,
  MeresFieldsFormValues,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/MeresFields'
import {
  ProtectionMeanItem,
  ProtectionMeansFieldsFormValues,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { RiskMapFilteredDanger } from '@models/riskMap'
import { DangersApi } from '@services/dangers'
import { protectionMeansApi } from '@services/protectionMeans'
import { convertMeanToForm } from '@utils/riskMap'
import { PositionOption } from '@models/position'
import { log } from '@utils/console'

export type State = {
  selectedDangers: RiskMapFilteredDanger[]
  selectedMeresForms: MeresFieldsFormValues[]
  selectedProtectionMeansForms: ProtectionMeansFieldsFormValues[]
  meresIsVisibleIndex: number | null
  protectionMeansIsVisibleIndex: number | null
  selectDangersModalIsVisible: boolean
  selectedMainPosition: PositionOption | null
}

const initialState: State = {
  selectedDangers: [],
  selectedMeresForms: [
    {
      prefilled: false,
      notAutoAddControlMeasures: false,
      meres: [{} as MereFormItem],
    },
  ],
  selectedProtectionMeansForms: [
    {
      prefilled: false,
      notAutoAddProtectionMeans: false,
      protectionMeans: [{} as ProtectionMeanItem],
    },
  ],
  meresIsVisibleIndex: null,
  protectionMeansIsVisibleIndex: null,
  selectDangersModalIsVisible: false,
  selectedMainPosition: null,
}

export const riskMapModalSlice = createSlice({
  name: 'riskMapModal',
  initialState,
  reducers: {
    setSelectedDangers: (state, { payload }) => {
      state.selectedDangers.push(...payload)
    },
    setSelectDangersModalIsVisible: (state, { payload }) => {
      state.selectDangersModalIsVisible = payload
    },
    setSelectedMeresForms: (state, { payload }) => {
      log('setSelectedMeresForms', payload)
      state.selectedMeresForms = payload
    },
    updateSelectedMeresForms: (state, { payload }) => {
      const typedPayload = payload as {
        index: number
        value: MeresFieldsFormValues
      }

      log('updateSelectedMeresForms', typedPayload)
      state.selectedMeresForms = state.selectedMeresForms.map((item, index) =>
        index === typedPayload.index ? typedPayload.value : item
      )

      if (payload.index >= state.selectedMeresForms.length) {
        state.selectedMeresForms.push(payload.item)
      }
    },
    setSelectedProtectionMeansForms: (state, { payload }) => {
      log('setSelectedProtectionMeansForms', payload)
      state.selectedProtectionMeansForms = payload
    },
    updateSelectedProtectionMeansForms: (state, { payload }) => {
      const typedPayload = payload as {
        index: number
        value: ProtectionMeansFieldsFormValues
      }

      log('updateSelectedProtectionMeansForms', typedPayload)
      state.selectedProtectionMeansForms =
        state.selectedProtectionMeansForms.map((item, index) =>
          index === typedPayload.index ? typedPayload.value : item
        )

      if (payload.index >= state.selectedProtectionMeansForms.length) {
        state.selectedProtectionMeansForms.push(payload.item)
      }
    },
    setMeresIsVisibleIndex: (state, { payload }) => {
      state.meresIsVisibleIndex = payload
    },
    setProtectionMeansIsVisibleIndex: (state, { payload }) => {
      state.protectionMeansIsVisibleIndex = payload
    },
    cleanAllForms: () => {
      return initialState
    },
    deleteSelectedProtectionMeansForms: (state, { payload }) => {
      const typedPayload = payload as number
      log('deleteSelectedProtectionMeansForms', typedPayload)
      state.selectedProtectionMeansForms =
        state.selectedProtectionMeansForms.filter(
          (_item, index) => typedPayload !== index
        )
    },
    deleteSelectedMeresForms: (state, { payload }) => {
      const typedPayload = payload as number
      log('deleteSelectedMeresForms', typedPayload)
      state.selectedMeresForms = state.selectedMeresForms.filter(
        (_item, index) => typedPayload !== index
      )
    },
    cleanSavedSelectedForms: (state) => {
      state.selectedMeresForms = initialState.selectedMeresForms
      state.selectedProtectionMeansForms =
        initialState.selectedProtectionMeansForms
    },
    setSelectedMainPosition: (state, { payload }) => {
      state.selectedMainPosition = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      DangersApi.endpoints.getDangersSearchedMeres.matchFulfilled,
      (state, { payload: { items, modalIndex = 0 } }) => {
        if (isEmpty(items)) return

        state.selectedMeresForms = state.selectedMeresForms.map((item, index) =>
          index === modalIndex
            ? {
                notAutoAddControlMeasures: false,
                prefilled: true,
                meres: items.map((mere) => ({
                  mereKind: {
                    ...mere.kind,
                    label: mere.kind.value,
                    value: mere.kind.value,
                  },
                  content: {
                    ...mere.content,
                    label: mere.content.value,
                    value: mere.content.value,
                  },
                  source: undefined,
                  deadline: undefined,
                  responsible: undefined,
                })),
              }
            : item
        )
      }
    ),
      builder.addMatcher(
        protectionMeansApi.endpoints.getDangersSearchedProtectionMeans
          .matchFulfilled,
        (state, { payload: { items, modalIndex = 0 } }) => {
          if (isEmpty(items)) return

          state.selectedProtectionMeansForms =
            state.selectedProtectionMeansForms.map((item, index) =>
              index === modalIndex
                ? {
                    prefilled: true,
                    notAutoAddProtectionMeans: false,
                    protectionMeans: items.map((mean) =>
                      convertMeanToForm(mean)
                    ),
                  }
                : item
            )
        }
      )
  },
})

export const {
  setSelectedMeresForms,
  updateSelectedMeresForms,
  setSelectedProtectionMeansForms,
  updateSelectedProtectionMeansForms,
  cleanAllForms,
  setMeresIsVisibleIndex,
  setProtectionMeansIsVisibleIndex,
  setSelectDangersModalIsVisible,
  setSelectedDangers,
  deleteSelectedProtectionMeansForms,
  deleteSelectedMeresForms,
  cleanSavedSelectedForms,
  setSelectedMainPosition,
} = riskMapModalSlice.actions

export default riskMapModalSlice.reducer
