import StoreTags from '@enums/StoreTags'
import {
  WorkPlace,
  WorkPlaceDetailed,
  WorkPlaceOption,
  CreateWorkPlaceBody,
  CreateWorkPlaceResponse,
  UpdateWorkPlaceBody,
  UpdateWorkPlaceResponse,
} from '@models/workPlace'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/work_places'

export const WorkPlacesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkPlaces: builder.query<
      { items: WorkPlaceOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<WorkPlace>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.WORK_PLACES],
    }),
    createWorkPlace: builder.mutation<
      CreateWorkPlaceResponse,
      CreateWorkPlaceBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.WORK_PLACES, StoreTags.WORK_PLACE] : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateWorkPlace: builder.mutation<
      UpdateWorkPlaceResponse,
      UpdateWorkPlaceBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.WORK_PLACES, StoreTags.WORK_PLACE] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getWorkPlace: builder.query<WorkPlaceDetailed, number>({
      providesTags: [StoreTags.WORK_PLACE],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteWorkPlace: builder.mutation<void, number>({
      invalidatesTags: [StoreTags.WORK_PLACES],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    archiveWorkPlace: builder.mutation<void, number>({
      invalidatesTags: [StoreTags.WORK_PLACES],
      query: (id) => ({
        url: `${BASE_URL}/${id}/archive`,
        method: 'PUT',
      }),
    }),
    unarchiveWorkPlace: builder.mutation<void, number>({
      invalidatesTags: [StoreTags.WORK_PLACES],
      query: (id) => ({
        url: `${BASE_URL}/${id}/unarchive`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useGetWorkPlacesQuery,
  useCreateWorkPlaceMutation,
  useUpdateWorkPlaceMutation,
  useGetWorkPlaceQuery,
  useLazyGetWorkPlaceQuery,
  useDeleteWorkPlaceMutation,
  useArchiveWorkPlaceMutation,
  useUnarchiveWorkPlaceMutation,
} = WorkPlacesApi
