import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import useMe from '@hooks/useMe'
import { FilterState } from '@hooks/useItemsFilters'
import { setSearchValue } from '@utils/setSearchValue'
import { OnboardingProps } from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import ResetOnboarding from '@templates/ResetOnboarding'
import Expandable from '@organisms/Expandable'
import StyledButton from '@organisms/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import { SettingFilled } from '@ant-design/icons'
import AddButton from '@templates/AddButton'
import ReportsButton, { ReportsProp } from '@templates/ReportsButton'
import SearchInput from './SearchInput'
import {
  ActionsButtonWrapper,
  ActionsWrapper,
  AddButtonWrapper,
  LeftWrapper,
  ResetOnboardingWrapper,
  RightWrapper,
  SearchWrapper,
  TitleWrapper,
  Wrapper,
} from './styled'
import Title, { TitleProps } from './Title'

export type HeaderProps = {
  selectedTab?: number
  titleTabs?: Omit<TitleProps, 'onChangeSelected' | 'selected'>[]
  ModalContent?: FC<{ handleCloseModal: () => void }>
  SecondaryModalContent?: FC<{ handleCloseModal: () => void }>
  isLoading?: boolean
  addButtonTitle?: string
  secondaryAddButtonTitle?: string
  setFilters?: Dispatch<SetStateAction<FilterState>>
  withSearch?: boolean
  setFiltersState: Dispatch<SetStateAction<FilterState>>
  searchPlaceholder?: string
  reports?: ReportsProp
  reportsOnboarding?: OnboardingProps
  resetOnboardingCodes?: OnboardingCodes[]
  onResetOnboarding?: () => void
  actions?: ReactNode
  modalWithoutCloseOnOutsideClick?: boolean
  secondaryModalWithoutCloseOnOutsideClick?: boolean
}

const Header: FC<HeaderProps> = ({
  ModalContent,
  SecondaryModalContent,
  selectedTab = 0,
  titleTabs,
  isLoading,
  addButtonTitle,
  secondaryAddButtonTitle,
  setFilters,
  withSearch = false,
  reports,
  setFiltersState,
  searchPlaceholder,
  reportsOnboarding,
  resetOnboardingCodes,
  onResetOnboarding,
  actions,
  modalWithoutCloseOnOutsideClick,
  secondaryModalWithoutCloseOnOutsideClick,
}) => {
  const { isEmployee } = useMe()

  const handleSearch = (value: string) => {
    setFilters?.(setSearchValue(value))
    setFiltersState?.(setSearchValue(value))
  }

  return (
    <>
      <Wrapper>
        <LeftWrapper>
          {titleTabs && (
            <TitleWrapper>
              {titleTabs.map((item) => (
                <Title
                  {...item}
                  key={item.index}
                  selected={selectedTab}
                  isLoading={isLoading}
                />
              ))}
            </TitleWrapper>
          )}
          {withSearch && (
            <SearchWrapper id="search-block">
              <SearchInput
                placeholder={searchPlaceholder || 'Поиск'}
                onSearch={handleSearch}
              />
            </SearchWrapper>
          )}
        </LeftWrapper>
        <RightWrapper>
          {reports && !isEmployee && (
            <ReportsButton
              reports={reports}
              reportsOnboarding={reportsOnboarding}
            />
          )}
          {ModalContent && (
            <AddButtonWrapper>
              <AddButton
                ModalContent={ModalContent}
                addButtonTitle={addButtonTitle}
                withoutCloseOnOutsideClick={modalWithoutCloseOnOutsideClick}
              />
            </AddButtonWrapper>
          )}
          {SecondaryModalContent && (
            <AddButton
              ModalContent={SecondaryModalContent}
              addButtonTitle={secondaryAddButtonTitle}
              isLinkStyle
              id="secondary-add-button"
              withoutCloseOnOutsideClick={
                secondaryModalWithoutCloseOnOutsideClick
              }
            />
          )}
          {actions && (
            <Expandable
              withoutClose
              Content={() => (
                <ActionsButtonWrapper>
                  <StyledButton
                    leftIcon={<SettingFilled />}
                    buttonStyle={ButtonStyle.PRIMARY}
                  >
                    Действия
                  </StyledButton>
                </ActionsButtonWrapper>
              )}
              expandedContent={<ActionsWrapper>{actions}</ActionsWrapper>}
            />
          )}
        </RightWrapper>
      </Wrapper>
      {resetOnboardingCodes && (
        <ResetOnboardingWrapper>
          <ResetOnboarding
            resetOnboardingCodes={resetOnboardingCodes}
            onResetOnboarding={onResetOnboarding}
          />
        </ResetOnboardingWrapper>
      )}
    </>
  )
}

export default Header
