import * as React from 'react'
import { FC, ReactNode } from 'react'
import { useToggle } from 'react-use'
import { compact } from 'lodash'
import GrayButton from '@organisms/GrayButton'
import Modal from '@organisms/Modal'
import Table, { Column } from '@organisms/Table'
import { RiskMapDetailed } from '@models/riskMap'
import { formatServerDate } from '@utils/date'
import EditRiskMapButton from '@pages/RiskMaps/RiskMap/Body/EditRiskMapButton'
import { RiskMapDangerControlMeasure } from '@models/danger'
import { StyledTitle, Wrapper } from './styled'

const getColumns = (noActions = false): Column[] =>
  compact([
    {
      title: 'ФИО ответственного лица за выполнение мероприятий',
      dataIndex: 'responsible',
    },
    {
      title: 'Вид меры',
      dataIndex: 'kind',
    },
    {
      title: 'Содержание',
      dataIndex: 'content',
    },
    {
      title: 'Источник финансирования мероприятий',
      dataIndex: 'source',
    },
    {
      title: 'Плановый срок выполнения мероприятий',
      dataIndex: 'plannedAt',
    },
    !noActions && {
      title: '',
      dataIndex: 'edit',
    },
  ])

type DataItem = {
  responsible: string | undefined
  kind: string | undefined
  content: string | undefined
  source: string | undefined
  plannedAt: string | undefined
  edit: ReactNode
  key: string
}

type Props = {
  children: RiskMapDangerControlMeasure[]
  riskMap: RiskMapDetailed
  dangersIndex: number
  noActions?: boolean
}

const Meres: FC<Props> = ({
  children = [],
  riskMap,
  dangersIndex,
  noActions = false,
}) => {
  const [opened, toggleOpened] = useToggle(false)

  const tableData: DataItem[] = children.map(
    (
      {
        '@id': idVal,
        responsiblePerson,
        kind,
        content,
        fundingSource,
        plannedCompletionDate,
      },
      index
    ) => ({
      key: idVal,
      responsible: responsiblePerson?.fullName,
      kind: kind?.value,
      content: content?.value,
      source: fundingSource,
      plannedAt: plannedCompletionDate
        ? formatServerDate(plannedCompletionDate)
        : undefined,
      edit: (
        <EditRiskMapButton
          riskMap={riskMap}
          dangersIndex={dangersIndex}
          meresIndex={index}
        />
      ),
    })
  )

  return (
    <Wrapper>
      <GrayButton onClick={toggleOpened}>Меры</GrayButton>
      <Modal
        visible={opened}
        onModalClose={toggleOpened}
        withoutCloseOnOutsideClick
      >
        <StyledTitle>Меры</StyledTitle>
        <Table columns={getColumns(noActions)} data={tableData} />
      </Modal>
    </Wrapper>
  )
}

export default Meres
