import { UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react'
import { isEmpty, memoize, omit } from 'lodash'
import { Option } from '@organisms/Select'
import { FilterItem, FilterState } from '@hooks/useItemsFilters'
import { TopControllersContext } from '@modals/RiskMapModal'
import isEmptyForm from '@modals/RiskMapModal/Dangers/utils/isEmptyForm'
import useDangerWorkPlacesField from './useDangerWorkPlacesField'
import {
  getDangerHazardName,
  getEventName,
  getKindName,
  getObjectName,
  getWorkPlaceName,
} from './nameUtils'
import useDangerObjectsField from './useDangerObjectsField'
import useDangerKindsField from './useDangerKindsField'
import useDangerHazardsField from './useDangerHazardsField'
import useDangerEventsField from './useDangerEventsField'
import getFilters, { RiskMapDangersFieldNames } from './utils/getFilters'

const getFilterValue = (field: Option<string> | undefined) =>
  !field || field?.withManualEntryText ? undefined : field.value

export type StateType = {
  relations: Partial<Record<RiskMapDangersFieldNames, FilterItem>>
}

type OptionalParams = {
  dangersSetFilters?: Dispatch<SetStateAction<FilterState>>
  index?: number
  isRequiredForm?: boolean
  inSelectDangersModal?: boolean
  ignoreJobPositions?: boolean
}

const useCommonRiskMapFields = (
  { watch, setValue, formState }: UseFormReturn,
  optionalParams?: OptionalParams
) => {
  const {
    index,
    dangersSetFilters,
    isRequiredForm: isRequiredFormExternal,
    inSelectDangersModal = false,
    ignoreJobPositions = false,
  } = optionalParams || {}

  const skip = isEmptyForm(
    inSelectDangersModal
      ? formState.dirtyFields
      : (formState.dirtyFields.dangerDTOs || [])[index || 0] || {}
  )

  const { enabledEtn } = useContext(TopControllersContext)

  const workPlaceName = getWorkPlaceName(index)
  const objectName = getObjectName(index)
  const kindName = getKindName(index)
  const dangerHazardName = getDangerHazardName(index)
  const eventName = getEventName(index)

  const workPlace = watch(workPlaceName) as Option<string> | undefined
  const object = watch(objectName) as Option<string> | undefined
  const kind = watch(kindName) as Option<string> | undefined
  const hazard = watch(dangerHazardName) as Option<string> | undefined
  const event = watch(eventName) as Option<string> | undefined

  const isRequiredForm =
    typeof isRequiredFormExternal === 'boolean'
      ? isRequiredFormExternal
      : !!(workPlace || kind || hazard || event)

  const getSetter = memoize((name) => (value: Option) => {
    setValue(name, value)
  })

  const { field: dangerWorkPlacesField, setFilters: workPlaceSetFilters } =
    useDangerWorkPlacesField(
      {
        name: workPlaceName,
        isRequired: isRequiredForm,
      },
      getSetter(workPlaceName),
      skip
    )
  const { field: dangersObjects, setFilters: dangersObjectsSetFilters } =
    useDangerObjectsField(
      {
        name: objectName,
        isRequired: isRequiredForm,
      },
      getSetter(objectName),
      skip
    )
  const { field: dangerKindsField, setFilters: dangerKindsSetFilters } =
    useDangerKindsField(
      {
        name: kindName,
      },
      skip
    )
  const { field: dangerHazardsField, setFilters: dangerHazardsSetFilters } =
    useDangerHazardsField(
      {
        name: dangerHazardName,
      },
      getSetter(dangerHazardName),
      skip
    )
  const { field: dangerEventsField, setFilters: dangerEventsSetFilters } =
    useDangerEventsField(
      {
        name: eventName,
      },
      getSetter(eventName),
      skip
    )

  const prevRelationsRef = useRef<any>(null)

  const relations = {
    ...getFilters({
      workPlace: getFilterValue(workPlace),
      kind: getFilterValue(kind),
      hazard: getFilterValue(hazard),
      event: getFilterValue(event),
      object: getFilterValue(object),
    }),
    etn: enabledEtn ? 'true' : undefined,
  }
  const stringRelations = JSON.stringify(relations)

  useEffect(() => {
    if (prevRelationsRef.current === stringRelations) return

    workPlaceSetFilters({ relations })
    dangersObjectsSetFilters({ relations })
    dangerKindsSetFilters({ relations })
    dangerHazardsSetFilters({ relations })
    dangerEventsSetFilters({ relations })

    if (!isEmpty(omit(relations, 'etn'))) {
      dangersSetFilters?.({
        ...relations,
        ignoreJobPositions,
      })
    }

    prevRelationsRef.current = stringRelations
  }, [stringRelations])

  return {
    dangerWorkPlacesField,
    dangersObjects,
    dangerKindsField,
    dangerHazardsField,
    dangerEventsField,
  }
}

export default useCommonRiskMapFields
