import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { compact } from 'lodash'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import useJobFields from '@hooks/useJobFields'
import { Field } from '@templates/AddNewItemModal/Content'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectedMainPosition } from '@services/riskMapModal'
import { selectField } from '@templates/AddNewItemModal/templates'
import { dangersControlMeasureWorkplaceOptions } from '@const/dangersControlMeasureWorkplaceOptions'

type Props = {
  isDisabled: boolean
}

const DivisionFields: FC<Props> = ({ isDisabled }) => {
  const dispatch = useAppDispatch()
  const methods = useFormContext()
  const { company, filial, division, position } = useJobFields(methods)

  const selectedPosition = methods.watch('position')

  useEffect(() => {
    dispatch(setSelectedMainPosition(selectedPosition))
  }, [dispatch, selectedPosition])

  const fields: Field[] = compact([
    company?.({
      isRequired: true,
      isDisabled,
    }),
    filial?.({
      isRequired: true,
      isDisabled,
    }),
    division?.({
      isRequired: true,
      isDisabled,
    }),
    position?.({
      isRequired: true,
      isDisabled,
    }),
  ])

  return (
    <ExternalLineWrapper>
      <LineFields
        fields={fields}
        lineTitle="Карта рисков для:"
        id="risk-map-for"
      />
      <LineFields
        fields={[
          selectField({
            name: 'dangersControlMeasureWorkplace',
            label: 'Место работы сотрудников в указанной должности',
            isRequired: true,
            promptText:
              'От выбора будет зависеть набор мер для управления опасностями',
            options: dangersControlMeasureWorkplaceOptions,
          }),
        ]}
      />
    </ExternalLineWrapper>
  )
}

export default DivisionFields
