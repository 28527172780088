import { createDownloadExcelReportQuery } from '@utils/files'
import emptySplitApi from '../api'

const BASE_URL = '/api/probation_sheets'

export const probationSheetsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProbationSheetEmployeeReport: builder.query<void, string | number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/employee_report`,
          'Стажировочный лист.xlsx'
        )({}),
    }),
  }),
})

export const { useLazyGetProbationSheetEmployeeReportQuery } =
  probationSheetsApi
