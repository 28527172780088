import * as React from 'react'
import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import { formatServerDate } from '@utils/date'
import { useDeleteFilialMutation, useGetFilialQuery } from '@services/filials'
import { AccordionBodyProps } from '@organisms/Accordion'
import AccordionBodyField from '@organisms/AccordionBodyField'
import AccordionBodyFieldsEmployees from '@templates/AccordionBodyFieldsEmployees'
import AccordionBodySelectedTabs from '@templates/AccordionBodySelectedTabs'
import EditModal from '@templates/EditModal'
import EmployeesCountField from '@templates/EmployeesCountField'
import FilialModal from '@modals/FilialModal'
import DeleteButton from '@templates/DeleteButton'
import AddButton from '@organisms/AddButton'
import UploadExternalEducationsModal from '@modals/UploadExternalEducationsModal'
import useMe from '@hooks/useMe'
import { FooterWrapper } from './styled'
import DivisionListItem from './DivisionListItem'

type Props = { id: number } & AccordionBodyProps

const Body: FC<Props> = ({ onChangeSize, id, toggleLoading }) => {
  const { isAdmin } = useMe()

  const [deleteFilial, deleteData] = useDeleteFilialMutation()
  const { data } = useGetFilialQuery(id)
  const {
    company,
    fullTitle,
    curators = [],
    countEmployees = 0,
    subscriptionStartAt,
    subscriptionEndAt,
    subscriptionsCount = 0,
    usedSubscriptionsCount = 0,
    companyBranchSubdivisions = [],
    inn,
  } = data || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data?.id])

  if (!data) return null

  return (
    <div>
      <AccordionBodyField label="Полное наименование" value={fullTitle} />
      <AccordionBodyField label="ИНН" value={inn} />
      <AccordionBodyFieldsEmployees label="Кураторы филиала" users={curators} />
      <EmployeesCountField
        countEmployees={countEmployees}
        company={
          company
            ? {
                label: company.title,
                value: company['@id'],
              }
            : undefined
        }
        filial={
          data
            ? {
                label: data.title,
                value: data['@id'],
              }
            : undefined
        }
        isAccordionField
      />
      <AccordionBodyField
        label="Дата начала"
        value={
          subscriptionStartAt
            ? formatServerDate(subscriptionStartAt)
            : undefined
        }
      />
      <AccordionBodyField
        label="Дата окончания"
        value={
          subscriptionEndAt ? formatServerDate(subscriptionEndAt) : undefined
        }
      />
      <AccordionBodyField
        label="Количество учетных записей"
        value={subscriptionsCount}
      />
      <AccordionBodyField
        label="Количество использованных учетных записей"
        value={usedSubscriptionsCount}
      />
      <AccordionBodyField
        label="Количество доступных учетных записей"
        value={subscriptionsCount - usedSubscriptionsCount}
      />
      {companyBranchSubdivisions?.length > 0 && (
        <AccordionBodySelectedTabs
          onTabChange={onChangeSize}
          title="Подразделения"
          items={companyBranchSubdivisions.map((division) => ({
            id: String(division['@id']),
            title: division.title,
            children: (
              <DivisionListItem id={division.id} onSuccess={onChangeSize} />
            ),
          }))}
        />
      )}
      <FooterWrapper>
        <EditModal
          ModalContent={FilialModal}
          modalProps={{
            filial: data,
          }}
          withoutCloseOnOutsideClick
        />
        <EditModal
          CustomEditComponent={({ onClick }) => (
            <AddButton onClick={onClick}>
              Загрузить обучения вне платформы
            </AddButton>
          )}
          ModalContent={UploadExternalEducationsModal}
          modalProps={{ filialUri: data['@id'] }}
        />
        <DeleteButton
          {...deleteData}
          onClick={() => deleteFilial(data.id)}
          disabled={!isAdmin}
          promptText={
            !isAdmin
              ? 'Для удаления обратитесь к администратору платформы через почту support@expschool.ru'
              : undefined
          }
        />
      </FooterWrapper>
    </div>
  )
}

export default Body
