import { FC } from 'react'
import SelectDangers from './SelectDangers'
import DangersFields from './DangersFields'
import TopControllers from './TopControllers'

const Dangers: FC = () => {
  return (
    <>
      <TopControllers />
      <SelectDangers />
      <DangersFields />
    </>
  )
}

export default Dangers
