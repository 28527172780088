import { omit } from 'lodash'
import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDangerBody,
  CreateDangerResponse,
  Danger,
  DangerOption,
  SearchedDangerMere,
  SearchedDangerMereOption,
} from '@models/danger'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_dangers'
const JOB_TAGS = [StoreTags.DANGERS, StoreTags.DANGER]

export const DangersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangers: builder.query<
      { items: DangerOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Danger>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.hazard.value || '',
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGERS],
    }),
    createDanger: builder.mutation<CreateDangerResponse, CreateDangerBody>({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    getDangersSearchedMeres: builder.query<
      {
        items: SearchedDangerMereOption[]
        length: number
        modalIndex?: number
      },
      {
        hazard: string
        event: string
        modalIndex?: number
        dangersControlMeasureWorkplace: 'office' | null
      }
    >({
      query: (params) => ({
        url: BASE_URL + '/control_measures',
        method: 'GET',
        params: omit(params, 'modalIndex'),
      }),
      transformResponse: (
        response: ItemsFromResponse<SearchedDangerMere>,
        _meta,
        params
      ) => {
        return {
          items: response[HYDRA_MEMBER].map((item) => ({
            ...item,
            label: item['@id'],
            value: item['@id'],
          })),
          length: response[HYDRA_TOTAL_ITEMS],
          modalIndex: params.modalIndex,
        }
      },
      providesTags: [StoreTags.MEASURES],
    }),
  }),
})

export const {
  useGetDangersQuery,
  useCreateDangerMutation,
  useLazyGetDangersSearchedMeresQuery,
} = DangersApi
