import React, { FC } from 'react'
import { WorkPlaceDetailed } from '@models/workPlace'
import EmployeesCountField from '@templates/EmployeesCountField'
import { Option } from '@organs/Select'

type Props = {
  workPlace: WorkPlaceDetailed
}

const Reviews: FC<Props> = ({ workPlace }) => {
  const {
    jobConditionAssessment,
    subdivision,
    jobPosition,
    companyBranch,
    company,
  } = workPlace
  const {
    countEmployeesSoutNotReviewed,
    countEmployeesSoutAvailable,
    countEmployeesSoutReviewed,
    '@id': id,
  } = jobConditionAssessment || {}

  const commonFilters = {
    isAccordionField: true,
  } as {
    company?: Option
    filial?: Option
    division?: Option
    position?: Option
    isAccordionField: boolean
  }

  if (company) {
    commonFilters.company = {
      label: company.title,
      value: company['@id'],
    }
  }

  if (companyBranch) {
    commonFilters.filial = {
      label: companyBranch.title,
      value: companyBranch['@id'],
    }
  }

  if (subdivision) {
    commonFilters.division = {
      label: subdivision.title,
      value: subdivision['@id'],
    }
  }

  if (jobPosition) {
    commonFilters.position = {
      label: jobPosition.title,
      value: jobPosition['@id'],
    }
  }

  return (
    <>
      <EmployeesCountField
        {...commonFilters}
        label="Сотрудники, которым доступна карта СОУТ"
        countEmployees={countEmployeesSoutAvailable}
      />
      <EmployeesCountField
        {...commonFilters}
        label="Сотрудники, которые ознакомились с картой СОУТ"
        countEmployees={countEmployeesSoutReviewed}
        jobConditionAssessmentReviewed={id}
      />
      <EmployeesCountField
        {...commonFilters}
        label="Сотрудники, которые не ознакомились с картой СОУТ"
        countEmployees={countEmployeesSoutNotReviewed}
        jobConditionAssessmentNotReviewed={id}
      />
    </>
  )
}

export default Reviews
