import React, { FC } from 'react'
import { isNil } from 'lodash'
import { WorkPlaceDetailed } from '@models/workPlace'
import AccordionBodyField from '@organisms/AccordionBodyField'
import { formatServerDate } from '@utils/date'
import Title from '@organisms/Title'
import useDownloadFile from '@hooks/useDownloadFile'
import Link, { LinkStyle } from '@organisms/Link'
import Loader from '@organisms/Loader'

type Props = {
  workPlace: WorkPlaceDetailed
}

const Classes: FC<Props> = ({ workPlace }) => {
  const {
    jobConditionAssessment,
    workplaceNumber,
    title,
    similarWorkplacesCountAndNumbers,
    declared,
    etksEksEdition,
    code,
    usedProductionEquipment,
    usedMaterialsAndRaw,
    countOnWorkplace,
    countOnSimilarWorkplaces,
    womenCount,
    disabledCount,
    under18Count,
  } = workPlace
  const {
    cardNumber,
    date,
    file,
    workConditionsImprovementRecommendations,
    workAndRestRegimeRecommendations,
    employeeSelectionRecommendations,
  } = jobConditionAssessment || {}

  const { download, isLoading } = useDownloadFile(file)

  const noRecomends =
    isNil(workConditionsImprovementRecommendations) &&
    isNil(workAndRestRegimeRecommendations) &&
    isNil(employeeSelectionRecommendations)

  const noCounts =
    isNil(countOnWorkplace) &&
    isNil(countOnSimilarWorkplaces) &&
    isNil(womenCount) &&
    isNil(disabledCount) &&
    isNil(under18Count)

  return (
    <>
      <AccordionBodyField label="Номер карты" value={cardNumber} />
      <AccordionBodyField
        label="Дата карты"
        value={date ? formatServerDate(date) : undefined}
      />
      <AccordionBodyField
        label="Номер рабочего места"
        value={workplaceNumber}
      />
      <AccordionBodyField
        label="Файл карты"
        value={
          file ? (
            <Loader isLoading={isLoading}>
              <Link
                linkStyle={LinkStyle.DEFAULT}
                text="Файл"
                onClick={download}
              />
            </Loader>
          ) : undefined
        }
      />
      <AccordionBodyField
        label="Наименование профессии (должности) работника"
        value={title}
      />
      <AccordionBodyField
        label="Количество и номера аналогичных рабочих мест"
        value={similarWorkplacesCountAndNumbers}
      />
      <AccordionBodyField
        label="Декларируемое рабочее место"
        value={declared ? 'Да' : 'Нет'}
      />
      <AccordionBodyField label="Выпуск ЕТКС, ЕКС" value={etksEksEdition} />
      <AccordionBodyField label="Код профессии (должности)" value={code} />
      <AccordionBodyField
        label="Используемое производственное оборудование"
        value={usedProductionEquipment}
      />
      <AccordionBodyField
        label="Используемые материалы и сырье"
        value={usedMaterialsAndRaw}
      />
      {!noRecomends && (
        <>
          <Title style={{ marginBottom: 12, marginTop: 24 }}>
            Рекомендации
          </Title>
          <AccordionBodyField
            label="Рекомендации по улучшению условий труда"
            value={workConditionsImprovementRecommendations}
          />
          <AccordionBodyField
            label="Рекомендации по режимам труда и отдыха"
            value={workAndRestRegimeRecommendations}
          />
          <AccordionBodyField
            label="Рекомендации по подбору работников"
            value={employeeSelectionRecommendations}
          />
        </>
      )}
      {!noCounts && (
        <>
          <Title style={{ marginBottom: 12, marginTop: 24 }}>
            Численность работающих
          </Title>
          <AccordionBodyField
            label="На рабочем месте"
            value={countOnWorkplace}
          />
          <AccordionBodyField
            label="На всех аналогичных местах"
            value={countOnSimilarWorkplaces}
          />
          <AccordionBodyField label="Из них женщин" value={womenCount} />
          <AccordionBodyField
            label="Из них лиц до 18 лет"
            value={under18Count}
          />
          <AccordionBodyField label="Из них инвалидов" value={disabledCount} />
        </>
      )}
    </>
  )
}

export default Classes
