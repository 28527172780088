import React, { FC } from 'react'
import { WorkPlaceDetailed } from '@models/workPlace'
import { useGetJobConditionAssessmentsWarrantiesQuery } from '@services/jobConditionAssesments'
import Loader from '@organisms/Loader'
import { StyledLabel, StyledTable } from './styled'

type Props = {
  workPlace: WorkPlaceDetailed
}

const Warranties: FC<Props> = ({ workPlace }) => {
  const { jobConditionAssessment } = workPlace
  const { '@id': id } = jobConditionAssessment || {}
  const { isLoading, data } = useGetJobConditionAssessmentsWarrantiesQuery(id)

  if (isLoading) return <Loader isLoading />

  if (!data) return <h3>Что-то пошло не так</h3>

  return (
    <StyledTable width="50%" cellPadding="10px" cellSpacing="10px">
      <thead>
        <tr>
          <th>
            <StyledLabel>Гарантии и компенсации</StyledLabel>
          </th>
          <th>
            <StyledLabel>Требуемая</StyledLabel>
          </th>
          <th>
            <StyledLabel>Фактическая</StyledLabel>
          </th>
          <th>
            <StyledLabel>Основания</StyledLabel>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.items?.map(({ title, required, actual, reason, id }) => (
          <tr key={id}>
            <td>{title}</td>
            <td>{required ? 'Да' : 'Нет'}</td>
            <td>{actual ? 'Да' : 'Нет'}</td>
            <td>{reason}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

export default Warranties
