import React, { FC, useState } from 'react'
import { isAfter } from 'date-fns'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import Title from '@organisms/Title'
import InfoField from '@organisms/InfoField'
import { ReactComponent as BlackRound } from '@interface-images/black-round.svg'
import { RiskMap } from '@models/riskMap'
import { formatServerDate } from '@utils/date'
import PositionInfoField from '@templates/PositionInfoField'
import useHasJobs from '@hooks/useHasJobs'
import RiskMapStatus from '@templates/RiskMapStatus'
import DownloadRiskMap from '@templates/DownloadRiskMap'
import useSearchParam from '@hooks/useSearchParam'
import {
  LineWrapper,
  MiddleWrapper,
  Wrapper,
  StyledLabel,
  Container,
} from './styled'
import Body from './Body'
import DownloadReportRisk from './DownloadReportRisk'
import SizExpire from './SizExpire'
import CopyRiskMap from './CopyRiskMap'

const RiskMapItem: FC<ListItemProps<RiskMap>> = ({
  isLoading,
  opened,
  handleClickOnItem,
  item: {
    id,
    number,
    status,
    approvedAt,
    dangersCount,
    jobPosition,
    file,
    reviseBeforeAt,
    individualProtectionMeanExpires,
    individualProtectionMeanExpired,
  },
  index,
}) => {
  const idParam = useSearchParam('id')
  const openedOnMeans = useSearchParam('openedOnMeans')
  const initialActiveTabResult =
    idParam === String(id) && openedOnMeans === 'true' ? 2 : 0
  const [initialActiveTab, setInitialActiveTab] = useState(
    initialActiveTabResult
  )
  const { hasCompany, hasFilial, hasDivision } = useHasJobs()

  const { subdivision } = jobPosition || {}
  const { title: divisionTitle, companyBranch } = subdivision || {}
  const { title: filialTitle, company } = companyBranch || {}
  const { title: companyTitle } = company || {}

  const isExpired = isAfter(new Date(), new Date(reviseBeforeAt))

  const header: FC<AccordionHeaderProps> = () => (
    <Container>
      <Wrapper>
        <LineWrapper>
          <Title mySize="h4">Карта рисков № {number}</Title>
          <RiskMapStatus status={status} index={index} />
          <SizExpire
            index={index}
            individualProtectionMeanExpired={individualProtectionMeanExpired}
            individualProtectionMeanExpires={individualProtectionMeanExpires}
            onClick={() => setInitialActiveTab(2)}
          />
          {file && <DownloadRiskMap file={file} index={index} />}
          <DownloadReportRisk index={index} id={id} />
        </LineWrapper>
        <MiddleWrapper>
          <LineWrapper>
            {hasCompany && (
              <StyledLabel mySize="h4">
                {companyTitle} <BlackRound />
              </StyledLabel>
            )}
            {hasFilial && (
              <StyledLabel mySize="h4">
                {filialTitle} <BlackRound />
              </StyledLabel>
            )}
            {hasDivision && (
              <StyledLabel mySize="h4">
                {divisionTitle} <BlackRound />
              </StyledLabel>
            )}
            <PositionInfoField
              position={jobPosition}
              forAllUsers
              withoutTitle
            />
          </LineWrapper>
        </MiddleWrapper>
        <LineWrapper>
          <InfoField
            label="Дата утверждения"
            value={approvedAt ? formatServerDate(approvedAt) : null}
            withStopPropagation={false}
            isLoading={isLoading}
            withoutFixedWidth
          />
          <InfoField
            onTabClick={() => setInitialActiveTab(1)}
            label="Количество опасностей"
            value={dangersCount}
            withStopPropagation={false}
            isLoading={isLoading}
            withoutFixedWidth
          />
          <InfoField
            isExpired={isExpired}
            label="Дата пересмотра"
            value={
              reviseBeforeAt ? formatServerDate(reviseBeforeAt) : undefined
            }
            withStopPropagation={false}
            isLoading={isLoading}
            withoutFixedWidth
            withoutHover
            id={`reviseBeforeAt-${index}`}
          />
        </LineWrapper>
      </Wrapper>
      <CopyRiskMap riskMapId={id} />
    </Container>
  )

  return (
    <Accordion
      skeletonHeight={120}
      expanded={opened}
      initLoading={isLoading}
      handleClickOnHeader={handleClickOnItem}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
          index={index}
          initialActiveTab={initialActiveTab}
        />
      )}
    />
  )
}

export default RiskMapItem
