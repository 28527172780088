import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import {
  checkboxField,
  fileField,
  selectField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'

export const AUTO_NAME = 'createDangers'
export const AUTO_SIZ_NAME = 'autoAddProtectionMeans'

const useRiskMapFromFileFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)
  const createDangers: boolean = methods.watch(AUTO_NAME)

  return [
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        selectField({
          name: 'dangersControlMeasureWorkplace',
          label: 'Место работы сотрудников из всех должностей файла',
          isRequired: true,
          promptText:
            'От выбора будет зависеть набор мер для управления опасностями',
          options: [
            {
              value: 'office',
              label: 'В офисе',
            },
            {
              value: null,
              label: 'Не в офисе',
            },
          ],
        }),
        fileField({
          name: 'file',
          label: 'Файл',
          formats: ['xlsx'],
          isRequired: true,
        }),
      ]),
    },
    {
      fields: [
        checkboxField({
          name: AUTO_NAME,
          label: 'Автоматически добавить подходящие опасности к картам',
          promptText:
            'Если к указанной должности будут найдены подходящие опасности, они будут добавлены вместе с мерами.',
          widthMultiplier: 2,
          defaultValue: true,
        }),
      ],
    },
    {
      fields: [
        checkboxField({
          name: AUTO_SIZ_NAME,
          label: 'Автоматически добавить подходящие СИЗ к опасностям',
          widthMultiplier: 2,
          defaultValue: true,
          isDisabled: !createDangers,
        }),
      ],
    },
  ]
}

export default useRiskMapFromFileFields
