import styled from '@emotion/styled'
import { Label } from '@organisms/InfoField/styled'

export const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`

export const LineWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
`

export const MiddleWrapper = styled.div``

export const StyledLabel = styled(Label)`
  svg {
    height: 12px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`
