import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateEmployeeEducationBody,
  EmployeeEducation,
  EmployeeEducationDetailed,
  EmployeeEducationResponse,
  UpdateEmployeeEducationBody,
} from '@models/employeeEducation'
import {
  createDownloadExcelReportQuery,
  createDownloadPdfReportQuery,
} from '@utils/files'
import {
  ANTI_FIRE_REPORT,
  ANTI_FIRE_REPORT_NAME,
  ELECTRO_SAFE_REPORT,
  ELECTRO_SAFE_REPORT_NAME,
  INITIAL_BRIEFING_REPORT,
  INITIAL_BRIEFING_REPORT_NAME,
  OTHER_BRIEFING_REPORT_NAME,
} from '@const/reportNames'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/education_destinations'

export const employeeEducationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeEducations: builder.query<
      { items: EmployeeEducation[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<EmployeeEducationResponse>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.EMPLOYEE_EDUCATIONS],
    }),
    createEmployeeEducation: builder.mutation<
      EmployeeEducationDetailed,
      CreateEmployeeEducationBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.EMPLOYEE_EDUCATIONS] : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateEmployeeEducation: builder.mutation<
      EmployeeEducationDetailed,
      UpdateEmployeeEducationBody
    >({
      invalidatesTags: (result) =>
        result
          ? [StoreTags.EMPLOYEE_EDUCATIONS, StoreTags.EMPLOYEE_EDUCATION]
          : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getEmployeeEducation: builder.query<EmployeeEducationDetailed, number>({
      providesTags: [StoreTags.EMPLOYEE_EDUCATION],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteEmployeeEducation: builder.mutation<void, number>({
      invalidatesTags: [StoreTags.EMPLOYEE_EDUCATIONS],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    getEducationProtocolReport: builder.query<void, string | number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/protocol_report`,
          'Протокол обучения.xlsx'
        )({}),
    }),
    getMinistryLaborReportXml: builder.query<void, string | number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/ministry_labor_report_xml`,
          'Реестр обученных для Минтруда.xml'
        )({}),
    }),
    getMinistryLaborReportXlsx: builder.query<void, string | number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/ministry_labor_report_xlsx`,
          'Реестр обученных для Минтруда.xlsx'
        )({}),
    }),
    getElectricalSafetyReport: builder.query<void, CommonQueryParams>({
      query: createDownloadPdfReportQuery(
        `${BASE_URL}/${ELECTRO_SAFE_REPORT}`,
        `${ELECTRO_SAFE_REPORT_NAME}.pdf`
      ),
    }),
    getFireSafetyReport: builder.query<void, CommonQueryParams>({
      query: createDownloadPdfReportQuery(
        `${BASE_URL}/${ANTI_FIRE_REPORT}`,
        `${ANTI_FIRE_REPORT_NAME}.pdf`
      ),
    }),
    getEmployeesInitialBriefingReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `${BASE_URL}/${INITIAL_BRIEFING_REPORT}`,
        INITIAL_BRIEFING_REPORT_NAME + '.xlsx'
      ),
    }),
    getEmployeesOtherBriefingReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `${BASE_URL}/multiple_briefing_report`,
        OTHER_BRIEFING_REPORT_NAME + '.xlsx'
      ),
    }),
  }),
})

export const {
  useGetEmployeeEducationsQuery,
  useCreateEmployeeEducationMutation,
  useUpdateEmployeeEducationMutation,
  useGetEmployeeEducationQuery,
  useDeleteEmployeeEducationMutation,
  useLazyGetEducationProtocolReportQuery,
  useLazyGetMinistryLaborReportXmlQuery,
  useLazyGetMinistryLaborReportXlsxQuery,
  useLazyGetElectricalSafetyReportQuery,
  useLazyGetFireSafetyReportQuery,
  useLazyGetEmployeesInitialBriefingReportQuery,
  useLazyGetEmployeesOtherBriefingReportQuery,
} = employeeEducationApi
