import { Dispatch, SetStateAction } from 'react'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import DataSelect, { DataSelectProps, QueryType } from '@templates/DataSelect'
import { Option } from '@organisms/Select'
import { FieldForRender, FieldParams } from '..'

export type DataSelectFieldParams = {
  query: QueryType
  filters: any
  setFilters: Dispatch<SetStateAction<any>>
  isMulti?: boolean
  isDisabled?: boolean
  dropdownWidth?: number
  componentProps?: DataSelectProps
  isClearable?: boolean
  withManualEntryText?: boolean
  withShowLabelInDescription?: boolean
  onOneItem?: (option: Option) => void
  skip?: boolean
  prepareSearch?: (value: string) => string
} & FieldParams

const dataSelectField = (params: DataSelectFieldParams): FieldForRender => {
  const {
    query,
    label,
    isRequired,
    isMulti = false,
    widthMultiplier = 1,
    componentProps: props,
    isDisabled = false,
    dropdownWidth,
    isClearable,
    name,
    withManualEntryText = false,
    withShowLabelInDescription = false,
    filters,
    setFilters,
    onOneItem,
    skip,
    prepareSearch,
  } = params

  const componentProps: DataSelectProps = {
    ...props,
    prepareSearch,
    filters,
    setFilters,
    onOneItem,
    query,
    placeholderForManySelected: label,
    placeholder: withManualEntryText
      ? 'Вручную или начните поиск'
      : 'Начните ввод для поиска',
    isMulti,
    isDisabled,
    dropdownWidth,
    menuPosition: 'fixed',
    isClearable,
    withManualEntryText,
    skip,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    Component: DataSelect,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : { required: false }),
    },
    componentProps,
    widthMultiplier,
    id: name,
    descriptionPath: withShowLabelInDescription ? 'value.label' : undefined,
  }
}

export default dataSelectField
