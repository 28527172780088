import { FC } from 'react'
import ModalOnRoute from '@templates/ModalOnRoute'
import EmployeeEducationModal from '@modals/EmployeeEducationModal'

const EducationModalOnRoute: FC = () => {
  return (
    <ModalOnRoute
      Component={EmployeeEducationModal}
      withoutCloseOnOutsideClick
    />
  )
}

export default EducationModalOnRoute
