import { FC } from 'react'
import { compact } from 'lodash'
import { useToggle } from 'react-use'
import Select, { Option } from '@organisms/Select'
import { useReturnFromArchiveMutation } from '@services/riskMaps'
import { RiskMapDetailed } from '@models/riskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Modal from '@organisms/Modal'
import Prompt from '@organisms/Prompt'
import ApproveModal from './ApproveModal'
import ToArchiveModal from './ToArchiveModal'

const TO_ARCHIVE_STATUSES = new Set([
  RiskMapStatuses.APPROVED,
  RiskMapStatuses.CHANGED,
  RiskMapStatuses.EXTENDED,
])

enum ChangeStatusEnum {
  APPROVED,
  ARCHIVE,
  RETURN_FROM_ARCHIVE,
}

const getOptions = (status: RiskMapStatuses) =>
  compact([
    status === RiskMapStatuses.DRAFT && {
      label: 'Утвержден',
      value: ChangeStatusEnum.APPROVED,
    },
    TO_ARCHIVE_STATUSES.has(status) && {
      label: 'Архив',
      value: ChangeStatusEnum.ARCHIVE,
    },
    status === RiskMapStatuses.ARCHIVED && {
      label: 'Вернуть из архива',
      value: ChangeStatusEnum.RETURN_FROM_ARCHIVE,
    },
  ])

type Props = {
  data: RiskMapDetailed
  index: number
}

const ChangeStatus: FC<Props> = ({ data, index }) => {
  const [approveModalIsVisible, toggleApproveModalIsVisible] = useToggle(false)
  const [toArchiveModalIsVisible, toggleToArchiveModalIsVisible] =
    useToggle(false)

  const [returnFromArchive, { isLoading: returnFromArchiveIsLoading }] =
    useReturnFromArchiveMutation()

  const handleChange = (newValue: Option<ChangeStatusEnum>) => {
    switch (newValue.value) {
      case ChangeStatusEnum.APPROVED:
        return toggleApproveModalIsVisible(true)
      case ChangeStatusEnum.ARCHIVE:
        return toggleToArchiveModalIsVisible(true)
      case ChangeStatusEnum.RETURN_FROM_ARCHIVE:
        return void returnFromArchive(data)
      default:
        return null
    }
  }

  const isCanceled = data.status === RiskMapStatuses.CANCELED
  const options = getOptions(data.status)

  return (
    <>
      <Select
        id={`select-risk-map-status-${index}`}
        menuPosition="fixed"
        placeholder="Выбрать статус"
        options={options}
        withoutFillSelected
        isSearchable={false}
        isClearable={false}
        onChange={handleChange}
        isLoading={returnFromArchiveIsLoading}
        isDisabled={isCanceled || options.length === 0}
      />
      {isCanceled && (
        <Prompt promptText="Вы не можете изменить статус отменённой карты" />
      )}
      <Modal
        visible={approveModalIsVisible}
        onModalClose={toggleApproveModalIsVisible}
      >
        <ApproveModal data={data} />
      </Modal>
      <Modal
        visible={toArchiveModalIsVisible}
        onModalClose={toggleToArchiveModalIsVisible}
      >
        <ToArchiveModal
          data={data}
          closeModal={toggleToArchiveModalIsVisible}
        />
      </Modal>
    </>
  )
}

export default ChangeStatus
